//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ReactElement } from "react";
import React, { createRef } from 'react';
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
    // Customizable Area Start
    children: ReactElement;
    onClose?: () => {}
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isOpen: boolean;
    position: { top: number; left: number };
    menuWidth: number;
    menuHeight: number;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

// Customizable Area Start

// Customizable Area End


export default class PopupMenuController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    menuRef: React.RefObject<HTMLDivElement>;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isOpen: false,
            position: { top: 0, left: 0 },
            menuWidth: 0,
            menuHeight: 0
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.menuRef = createRef<HTMLDivElement>();
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps: {}, prevState: Readonly<S>) {
        if (this.state.isOpen && !prevState.isOpen) {
            this.adjustPosition();
        }
    }

    handleScroll = () => {
        this.closeMenu();
    };

    handleClickOutside = (event) => {
        if (this.menuRef.current && !this.menuRef.current.contains(event.target)) {
            this.closeMenu();
        }
    };

    adjustPosition = () => {
        if (this.menuRef.current) {
            const { top, left } = this.state.position;
            const menuWidth = this.menuRef.current.offsetWidth;
            const menuHeight = this.menuRef.current.offsetHeight;
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            const defaultIndentation = 10

            let adjustedTop = top;
            let adjustedLeft = left;

            if (top + menuHeight > windowHeight) {
                adjustedTop = windowHeight - menuHeight - defaultIndentation;
            } else if (top < 0) {
                adjustedTop = defaultIndentation;
            }

            if (left + menuWidth > windowWidth) {
                adjustedLeft = windowWidth - menuWidth - defaultIndentation;
            } else if (left < 0) {
                adjustedLeft = defaultIndentation;
            }

            this.setState({ position: { top: adjustedTop, left: adjustedLeft }, menuWidth, menuHeight });
        }
    };

    openMenu = (posX: number, posY: number) => {
        this.setState({
            isOpen: true,
            position: { top: posY, left: posX },
        });
    };

    closeMenu = () => {
        this.setState({ isOpen: false });
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    // Customizable Area End
}
