import React from "react";
import PopupMenuController, { Props } from "./PopupMenuController";
import 'react-quill/dist/quill.snow.css';

export default class PopupMenu extends PopupMenuController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            <>
            {this.state.isOpen && (
              <div
                ref={this.menuRef}
                style={{
                  zIndex: 4,
                  position: 'fixed',
                  top: `${this.state.position.top}px`,
                  left: `${this.state.position.left}px`,
                }}
              >
                {this.props.children}
              </div>
            )}
          </>
        );
    }
}

// Customizable Area Start

// styles

// Customizable Area End