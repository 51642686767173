import React from 'react';

// Customizable Area Start
import {
  Modal,
  FormControl,
  Select,
  MenuItem,
Menu,
  Container,
  Box,
  Chip,
  Button,
  Input,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  ListItemText,
  ListItemIcon,
  ListItem,
  Typography,
  InputLabel,
  List,
  TablePagination,
  Pagination,
  TableFooter,
  TextField
} from '@mui/material';
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { Account, Group, GroupAccount } from './types';
import FilterListIcon from '@mui/icons-material/FilterList';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Sidebar from "./../../../components/src/sidebar/Sidebar.web";
const theme = createTheme({
  palette: {
    primary: {
      main: '#0000ff',
      contrastText: '#fff',
    },
  },
});
// Customizable Area End

import AccountGroupsController, {
  configJSON,
  Props,
} from './AccountGroupsController';

export default class AccountGroups extends AccountGroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { currentPage, itemsPerPage, accountsData } = this.state;
    const totalPages = Math.ceil(accountsData.length / itemsPerPage);
    const startResult = currentPage * itemsPerPage + 1;
    const endResult = Math.min(startResult + itemsPerPage - 1, accountsData.length);

    
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box sx={webStyles.mainWrapper}>
            <Box sx={webStyles.mainBlock}>


            <Sidebar navigation={this.props.navigation} current='clients' />

              <Box sx={webStyles.pageContent} data-testid="pageContent">
                <Box sx={webStyles.contentHeader}>
                  <Box sx={webStyles.navigation}>
                    <Box sx={webStyles.breadcrumbButton}>Clients</Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "white", color: "black", marginRight: '10px', width: "169px", height: '44px' }}
                      > 
                        Export
                      </Button>

                      <Button
                        variant="contained"
                        style={{ backgroundColor: '#1C1C1C', width: "169px", height: '44px', color: 'white',whiteSpace: 'nowrap'}}
                        startIcon={<GroupAddIcon />}
                      >
                        Add New Client
                      </Button>

                    </Box>
                  </Box>
                </Box>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search..."
                  sx={{ width: "50%", height: "50px", marginLeft: "30px" }}
                  value={this.state.searchQuery}
                  onChange={this.handleSearchChange}/>
                <Box display="flex" alignItems="center" justifyContent="space-between" p={2} bgcolor="#f5f5f5">
                  <Box display="flex" alignItems="center">
                    {this.state.selectedCount > 0 && (
                      <>
                        <Typography variant="h6">{this.state.selectedCount} selected</Typography>
                        <Button variant="contained" sx={{ marginLeft: "20px", borderRadius: "4px", border: "2px ", borderColor: '#F87171', backgroundColor: '#DC2626', opacity: "90%", width: "71px", height: "40px" }}>
                          Archive
                        </Button>
                      </>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: "center" }}>

                    <Box display="flex" alignItems="center" sx={{ marginRight: "20px" }}>
                      <Typography variant="h6">Filter</Typography>
                      <IconButton aria-label="filter">
                        <FilterListIcon />
                      </IconButton>
                    </Box>

                    {/* Sort */}
                    <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                      <InputLabel>Sort by:</InputLabel>
                      <Select value={this.state.sortBy} onChange={(e: any) => this.handleSortChange(e)} label="Sort by:" data-testid="sort-select">
                        <MenuItem value="A-Z">A-Z</MenuItem>
                        <MenuItem value="Z-A">Z-A</MenuItem>
                        <MenuItem value="relevance">Relevance</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Container maxWidth={false} sx={{ width: "100%" }}>

                  <Paper style={webStyles.tableViewStyle}>
                    <TableContainer style={webStyles.tableContainerStyle}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{textAlign:"left",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>   </TableCell>
                            <TableCell sx={{textAlign:"left",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>{configJSON.tableHeaderCompany}</TableCell>
                            <TableCell sx={{textAlign:"left",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>{configJSON.tableHeaderAdminUser}</TableCell>
                            <TableCell sx={{textAlign:"left",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>{configJSON.tableHeaderPackages}</TableCell>
                            <TableCell sx={{textAlign:"right",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>{configJSON.tableHeaderTotalNumberOfUsers}</TableCell>
                            <TableCell sx={{textAlign:"right",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>{configJSON.tableHeaderDateCreated}</TableCell>
                            <TableCell sx={{textAlign:"right",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>{configJSON.tableHeaderStatus}</TableCell>

                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {accountsData.slice(currentPage * itemsPerPage, currentPage * itemsPerPage + itemsPerPage).map((account) => (
                            <TableRow key={account.id}>

                              <TableCell sx={{textAlign:"left"}}>
                                <Checkbox
                                sx={{
                                  '&.Mui-checked': {
                                    color: 'black', 
                                    borderRadius: '6px', 
                                    '&:hover': {
                                     backgroundColor: 'rgba(0, 0, 0, 0.08)', 
                                     },},
                                }}    
                                  checked={account.isSelected}
                                  onChange={() => this.handleCheckboxChange(account.id)}
                                  data-testid={`checkbox-${account.id}`}
                                />
                              </TableCell>

                              <TableCell sx={{textAlign:"left"}}>
                                {account.attributes.company_name}
                              </TableCell>
                              <TableCell sx={{textAlign:"left"}}>{account.attributes.contact_name}</TableCell>
                              <TableCell sx={{textAlign:"left"}} >Premium</TableCell>
                              <TableCell sx={{textAlign:"right"}}>10</TableCell>
                              <TableCell sx={{textAlign:"right"}}>{new Date(account.attributes.created_at!).toLocaleDateString()}</TableCell>
                              <TableCell sx={{textAlign: 'right'}}>Active</TableCell>
                              <TableCell sx={{textAlign:"right"}}>
                                <IconButton
                                  onClick={(event) => this.handleMenuOpen(event, account.id)}
                                >
                                  <MoreVertIcon />
                                </IconButton>

                                <Menu
                                  anchorEl={this.state.anchorEl[account.id]}
                                  open={Boolean(this.state.anchorEl[account.id])}
                                  onClose={() => this.handleMenuClose(account.id)}
                                  sx={{ ml: "4" }}
                                >
                                  <MenuItem onClick={() => this.handleMenuClose(account.id)}>View</MenuItem>
                                  <MenuItem onClick={() => this.handleMenuClose(account.id)}>Edit</MenuItem>
                                  <MenuItem onClick={() => this.handleMenuClose(account.id)}>Delete</MenuItem>
                                </Menu>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                                <div style={{ flex: 1 }}></div>

                                <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                                  <Pagination
                                    count={Math.ceil(accountsData.length / itemsPerPage)}
                                    page={currentPage + 1}
                                    onChange={(event, value) => this.handleChangePage(null, value - 1)}
                                    siblingCount={1}
                                    boundaryCount={1}

                                  />
                                </div>

                                <div style={{ flex: 1, textAlign: 'right' }}>
                                  {`${startResult}-${endResult} of ${accountsData.length} results`}
                                </div>

                              </div>
                            </TableCell>
                          </TableRow>
                        </TableFooter>

                      </Table>
                    </TableContainer>
                  </Paper>
                </Container>
              </Box>



              <Modal open={this.state.isVisibleModal} onClose={this.hideModal}>
                <Box sx={webStyles.modalStyle}>
                  {this.state.fieldError && (
                    <p style={webStyles.errorMsg}>
                      {configJSON.errorAllFieldsAreMandatory}
                    </p>
                  )}
                  <Box sx={webStyles.modalRowViewStyle}>
                    <Input
                      data-test-id={'inputName'}
                      placeholder={configJSON.textName}
                      defaultValue={this.state.editMode ? this.state.name : ''}
                      onChange={(e) => this.handleInputName(e.target.value)}
                    />
                    <Button
                      data-test-id="btnAddGroup"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.state.editMode
                          ? this.editGroup(this.state.id)
                          : this.addGroup();
                      }}
                    >
                      {this.state.editMode
                        ? configJSON.textUpdate
                        : configJSON.textSave}
                    </Button>
                  </Box>

                  <Box sx={webStyles.modalButtonViewStyle}>
                    <Button
                      data-test-id="btnHideModal"
                      variant="contained"
                      onClick={this.hideModal}
                    >
                      {configJSON.textClose}
                    </Button>
                  </Box>
                </Box>
              </Modal>

              {/* Add/Edit Accounts to the group modal */}
              <Modal
                open={this.state.isVisibleAddAccountModal}
                onClose={this.hideAddAccountModal}
              >
                <Box sx={webStyles.modalStyle}>
                  <Box sx={webStyles.modalRowViewStyle}>
                    <p>{this.state.name}</p>
                  </Box>
                  <Box sx={webStyles.dropdownViewStyle}>
                    <p>{configJSON.textSelectAccounts}</p>
                    <IconButton
                      data-test-id={'btnExpandAccountsView'}
                      onClick={this.expandAccountsView}
                      edge="end"
                      size="large"
                    >
                      {this.state.dropdownAccountStatus ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </IconButton>
                  </Box>
                  {this.state.dropdownAccountStatus && (
                    <Paper style={webStyles.dropdownListContainer}>
                      <List>
                        {this.state.modalAccData.map(
                          (account: Account, index: number) => {
                            const labelId = `checkbox-list-label-${index}`;
                            return (
                              <ListItem
                                key={account.id}
                                data-test-id={'listItem' + index}
                                dense
                                button
                                onClick={() =>
                                  this.handleAccountSelect(account.id)
                                }
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={account.isSelected}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  primary={
                                    account.attributes.first_name! + account.attributes.last_name!
                                  }
                                />
                              </ListItem>
                            );
                          }
                        )}
                      </List>
                    </Paper>
                  )}

                  <Box sx={webStyles.modalButtonViewStyle}>
                    <Button
                      data-test-id="btnSaveAccountsToGroup"
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        this.handleSaveAccountsToGroup(this.state.id)
                      }
                    >
                      {configJSON.textSave}
                    </Button>
                    <Button
                      data-test-id="btnCloseAddAccountModal"
                      variant="contained"
                      onClick={this.hideAddAccountModal}
                    >
                      {configJSON.textClose}
                    </Button>
                  </Box>
                </Box>
              </Modal>

              {/* Delete Accounts from the group modal */}
              <Modal
                open={this.state.isVisibleDeleteAccountModal}
                onClose={this.hideDeleteAccountModal}
              >
                <Box sx={webStyles.modalStyle}>
                  <Box sx={webStyles.modalRowViewStyle}>
                    <p>{this.state.name}</p>
                  </Box>
                  <Paper style={webStyles.dropdownListContainer}>
                    <List>
                      {this.state.selectedAccounts.map(
                        (account: GroupAccount, index: number) => {
                          const labelId = `checkbox-list-label-${index}`;
                          return (
                            <ListItem
                              key={account.id}
                              data-test-id={'deleteListItem' + index}
                              dense
                              button
                              onClick={() =>
                                this.handleDeleteAccountSelect(account.id)
                              }
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={account.isSelected}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                id={labelId}
                                primary={
                                  account.first_name + " " + account.last_name
                                }
                              />
                            </ListItem>
                          );
                        }
                      )}
                    </List>
                  </Paper>
                  <Box sx={webStyles.modalButtonViewStyle}>
                    <Button
                      data-test-id="btnHandleRemoveAccountsToGroup"
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        this.handleRemoveAccountsToGroup(this.state.id)
                      }
                    >
                      {configJSON.textDelete}
                    </Button>
                    <Button
                      data-test-id="btnHideDeleteAccountModal"
                      variant="contained"
                      onClick={this.hideDeleteAccountModal}
                    >
                      {configJSON.textClose}
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
          </Box>
          <Box sx={webStyles.footerBlock}>
            <Box sx={webStyles.leftFooter}>
              <Box>Terms and Conditions</Box>
              <Box>Privacy Policy</Box>

              <Box></Box>
            </Box>

            <Box>
              <Box>� 2024 Thinkspike, All Rights Reserved</Box>
            </Box>
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  modalStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
  footerBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '24px 52px 24px 52px',
    backgroundColor: '#1C1C1C',
    color: '#fff',
  },
  leftFooter: {
    display: 'flex',
    gap: '24px',
    flexGrow:1
  },
  rightFooter: {},
  modalRowViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdownViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 280,
    justifyContent: 'space-between',
    padding: 10,
    border: '1px solid #ccc',
    borderRadius: 5,
  },
  modalButtonViewStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 0px',
  },
  tableViewStyle: {
    width: '100%',
    overflow: 'hidden',
  },
  tableContainerStyle: {
    maxHeight: 600,
    width:'100%'
  },
  tableButtonViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerButtonViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  secondButtonViewStyle: {
    marginLeft: 10,
  },
  btnAddAccounts: {
    backgroundColor: '#005500',
    marginLeft: 10,
  },
  btnDeleteAccounts: {
    backgroundColor: '#990000',
    marginLeft: 10,
  },
  checkCircleRed: {
    height: 10,
    width: 10,
    backgroundColor: 'red',
    borderRadius: 5,
  },
  innerTableBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  arrow: {
    height: 20,
    width: 20,
    resizeMode: 'contain',
  },
  dropdownListContainer: {
    maxHeight: 200,
    overflow: 'auto',
    width: 300,
  },
  errorMsg: {
    color: 'red',
    margin: 10,
  },
  mainWrapper: {
    flex: 1,
    flexDirection: 'col',
    backgroundColor: '#f6f6f6',

  },
  mainBlock: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor:'#f6f6f6',
  },
  pageContent: {
    display: 'flex',
    overflowX:'auto',
    flexDirection: 'column', 
    alignItems:'left',
    width:"100%",
    padding: '0px 40px',
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    textAlign:"center",
    justifyContent:'center',
    color:"black"
  },
  contentHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 20px'
  },
  navigation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  currentNavigationPage: {
    fontWeight: '700',
    fontSize: '24px',
    padding: '4px 8px'
  },
  breadcrumbButton: {
    fontSize: '24px',
    padding: '4px 8px'
  },
};
// Customizable Area End
