import React from "react";

// Customizable Area Start
// Customizable Area End

import TermsConditionsUsersController, {
  Props,
  configJSON,
} from "./TermsConditionsUsersController";

export default class TermsConditionsUsers extends TermsConditionsUsersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div></div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
