//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { createRef } from 'react';
import { dummyImage, dummyVideo, userImage } from './assets';

const defaultCarouselContent = {
  media: dummyImage,
  type: "image",
  heading: `<p style="font-family:'Crimson Text';font-size:24px;font-weight:bold;">This is heading for carousel</p>`,
  description: `<p style="font-family:'Crimson Text';font-size:18px">This is subtext</p>`,
};

const defaultContent = {
  "Text": {
    type: "Text",
    paddingTop: 30,
    paddingBottom: 30,
    width: "70vw",
    heading: `<p style="font-family:'Crimson Text';font-size:24px;font-weight:bold;">Heading</p>`,
    content: `<p style="font-family:'Crimson Text';font-size:20px">At its core, UX design is about enhancing the overall experience a user has while interacting with a product, whether it's a website, an app, or any digital tool. UX design involves understanding the users' needs, behaviors, and motivations to create products that solve real problems in an intuitive way.</p>`,
  },
  "List": {
    type: "List",
    paddingTop: 30,
    paddingBottom: 30,
    width: "70vw",
    listType: "unordered",
    heading: `<p style="font-family:'Crimson Text';font-size:24px;font-weight:bold;">This is the subheading with bullet points.</p>`,
    content: `<ul>
        <li style="font-family:'Crimson Text';font-size:18px;">Research: Gathering information about users and their needs through interviews, surveys, and observations.</li>
        <li style="font-family:'Crimson Text';font-size:18px;">Empathy: Understanding and sharing the feelings of users, which helps in creating user-focused designs.</li>
        <li style="font-family:'Crimson Text';font-size:18px;">Defining the Problem: Based on the research, identifying the core issues users face that need to be addressed.</li>
      </ul>`
  },
  "Image": {
    type: "Image",
    paddingTop: 30,
    paddingBottom: 30,
    width: "70vw",
    image: dummyImage,
    caption: "This is the image caption, you can left it empty if not needed"
  },
  "Video": {
    type: "Video",
    paddingTop: 30,
    paddingBottom: 30,
    width: "70vw",
    video: dummyVideo,
    caption: "This is the caption of the video you can add, if not needed then leave empty"
  },
  "Quote": {
    type: "Quote",
    paddingTop: 30,
    paddingBottom: 30,
    width: "70vw",
    image: userImage,
    content: `<blockquote style="text-align:center;"><span style="font-size:16px;font-family:Crimson Text;">"Consistency is one of the most powerful usability principles: when things always behave the same, users don't have to worry about what will happen."</span></blockquote>`,
    name: `<p style="text-align:center;"><span style="font-size:20px;font-family:Crimson Text;">Jakob Nielson</span></p>`,
  },
  "Labeled graphic":{
    type: "Label",
    paddingTop: 30,
    width:'70vw',
    paddingBottom: 30,
    image: dummyImage,
    content:[
      {
        id:'1',
        x:100,
        y:100,
        title:`<p style="font-family:'Crimson Text';font-size:16px;font-weight:bold;">Heading of Card</p>`,
        description: `<p style="font-family:'Crimson Text';font-size:14px">This is the subtext that you can edit in the editor</p>`,
        visibleContent:false,
        contentRightSide:true
      }
    ]
  },
  "Carousel": {
    type: "Carousel",
    paddingTop: 30,
    paddingBottom: 30,
    width: "70vw",
    content: [defaultCarouselContent]
  },
  "Table":{
    type:"Table",
    paddingTop: 30,
    paddingBottom: 30,
    width: "70vw",
    content: [
      {
        "heading1": "<p>Heading 1</p>",
        "heading2": "<p>Heading 2</p>",
        "heading3": "<p>Heading 3</p>",
        "heading4": "<p>Heading 4</p>",
        "table_rows_attributes": [
          {
            "column1": "<p>Row 1 Column 1</p>",
            "column2": "<p>Row 1 Column 2</p>",
            "column3": "<p>Row 1 Column 3</p>",
            "column4": "<p>Row 1 Column 4</p>"
          },
          {
            "column1": "<p>Row 2 Column 1</p>",
            "column2": "<p>Row 2 Column 2</p>",
            "column3": "<p>Row 2 Column 3</p>",
            "column4": "<p>Row 2 Column 4</p>"
          }
        ]
      }
    ]
  },
  "Flash card":{
    type:"Card",
    paddingTop: 30,
    paddingBottom: 30,
    cards:[
      {
        id:'1',
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card 1</p>`,
        back:`<p style="font-family:'Crimson Text';font-size:14px">True. Both UX and UI designers often use these tools for different purposes—UX for wireframes/prototypes and UI for visual designs.</p>`,
        flipped:true
      },
      {
        id:'2',
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card 2</p>`,
        back:`<p style="font-family:'Crimson Text'font-size:14px">Back of Card 2</p>`,
        flipped:false
      },
      {
        id:'3',
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card 3</p>`,
        back:`<p style="font-family:'Crimson Text';font-size:14px">Back of Card 2</p>`,
        flipped:false
      },
      {
        id:'4',
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card 4</p>`,
        back:`<p style="font-family:'Crimson Text';font-size:14px">Back of Card 2</p>`,
        flipped:false
      },
      {
        id:'5',
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card 5</p>`,
        back:`<p style="font-family:'Crimson Text';font-size:14px">Back of Card 2</p>`,
        flipped:false
      },
      {
        id:'6',
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card 6</p>`,
        back:`<p style="font-family:'Crimson Text';font-size:14px">Back of Card 2</p>`,
        flipped:false
      },
    ]
  }
};

export type PaginationProps = {
  pages: number;
  activePage: number;
  onClick: (page: number) => void;
}

export type CarouselButtonProps = {
  type: "PREV" | "NEXT";
  isEdge: boolean;
  onClick: () => void;
}

export interface DefaultInputTitle {
  title: string,
  isActiveToChange: boolean
}

export interface LessonItem {
  id: number,
  lessonTitle: DefaultInputTitle,
  isPopUpActive: boolean;
  courseData: any[];
  quizData: any[];
}

export interface LessonCreationBar {
  title: string,
  isActive: boolean,
}

export interface Position {
  x: number,
  y: number
}

export type LessonListType = "bullet" | "numbered" | "empty";
export const crypto = require('crypto');

// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  // Customizable Area Start
  navigation: object;
  idContent: string | number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  description: string;
  lessonCreationBar: LessonCreationBar;
  lessonList: LessonItem[];
  lessonListType: LessonListType;
  courseTitle: string;
  authorName: string
  lessonTitle: string;
  lessonAuthorName: string;
  currentLessonId: number;
  isLessonVisible: boolean;
  isToolbarVisible: boolean;
  isFormatVisible: boolean;
  courseData: any;
  currentObj: any;
  currentIndex: number;
  isDrawerOpen: boolean;
  buttonType: string;
  isSidebarOpen: boolean;
  top: number;
  bottom: number;
  isPreview: boolean;
  draggedItemIndex: null | number;
  isQuizVisible: boolean;
  quizData: any;
  quizObj: any;
  isQuizEditorVisibile: boolean;
  draggingIndex:number;
  currentParentIndex:number;
  offset:{x:number,y:number};
  selectedRowIndex: number,
  selectedColumnIndex: number,
  flip:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  lessonPopupMenuRef: React.RefObject<HTMLDivElement>;
  lessonTypeMenuRef: React.RefObject<HTMLDivElement>;
  bottomRef: React.RefObject<HTMLDivElement>;
  formatRef: React.RefObject<HTMLDivElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      description: `<p>This is the starting of the course description. following are the numbered formatting.</p>`,
      lessonCreationBar: { title: "", isActive: true },
      lessonList: [],
      lessonListType: "bullet",
      courseTitle: "",
      authorName: "",
      lessonTitle: "",
      lessonAuthorName: "",
      isInitialLoad: true,
      currentLessonId: -1,
      isLessonVisible: false,
      isToolbarVisible: false,
      courseData: [],
      currentObj: {},
      top: 0,
      bottom: 0,
      currentIndex: -1,
      isDrawerOpen: false,
      isSidebarOpen: false,
      buttonType: "",
      isPreview: false,
      draggedItemIndex: null,
      isQuizVisible: false,
      quizData: [],
      quizObj: {},
      isQuizEditorVisibile: false,
      offset: { x: 0, y: 0 },
      draggingIndex:null,
      selectedRowIndex: null,
      selectedColumnIndex: null,
      flip:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
        this.lessonPopupMenuRefs = {};
        this.lessonTypeMenuRef = createRef<HTMLDivElement>();
        this.bottomRef = createRef<HTMLDivElement>();
        this.formatRef = createRef<HTMLDivElement>();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  onGoBack = () => {
    if(this.state.isQuizVisible){
      this.handleQuizGoBack()
    }else{
      this.handleLessonGoBack()
    }
  }

  handleLessonGoBack = ()=>{
    let lessonList = [...this.state.lessonList];
    lessonList = lessonList.map((item) => (
      item.id === this.state.currentLessonId
        ? { ...item, courseData: this.state.courseData }
        : item
    ));
    this.setState({
      lessonList,
      courseData: [],
      currentObj: {},
      buttonType: "",
      isPreview: false,
      currentIndex: -1,
      currentLessonId: -1,
      isDrawerOpen: false,
      isSidebarOpen: false,
      isLessonVisible: false,
      isToolbarVisible: false,
    });
  }

  handleQuizGoBack = ()=>{
    let lessonList = [...this.state.lessonList];
    lessonList = lessonList.map((item) => (
      item.id === this.state.currentLessonId
        ? { ...item, quizData: this.state.quizData  }
        : item
    ));
    this.setState({
      lessonList,
      isQuizEditorVisibile:false,
      isQuizVisible:false,
      quizData:[],
      quizObj:{},
    });
  }

  sidebarHandler = () => {
    this.setState({
      currentIndex: this.state.courseData.length - 1,
      isSidebarOpen: !this.state.isSidebarOpen,
    });
  }

  openToolbar = () => {
    this.setState({ 
      buttonType: "",
      currentIndex: -1,
      isToolbarVisible: true,
    },() => {
      this.bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    });
  }

  handleTypeButtonClick = (label: string, isFromSidebar?: boolean) => {
    let courseData = [...this.state.courseData];
    const obj = defaultContent[label];
    if (obj) {
      const isScroll = isFromSidebar && this.state.currentIndex === this.state.courseData.length - 1;
      const index = isFromSidebar ? this.state.currentIndex : this.state.courseData.length - 1;
      courseData.splice(index + 1, 0, obj);
      this.setState({ courseData, currentIndex: -1, isToolbarVisible: false, isSidebarOpen: false }, () => {
        if (isScroll) {
          this.bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  }

  handleEditClick = (index: number) => {
    this.setState({ 
      currentIndex: index,
      buttonType: "edit",
      isDrawerOpen: true,
      currentObj: this.state.courseData[index],
    });
  }

  handleFormatClick = (index: number) => {
    if(this.state.buttonType === "format" && this.state.currentIndex === index) {
      this.hideFormatOption();
    } else {
      this.setState({
        currentIndex: index,
        buttonType: "format",
      }, () => {
        if (this.formatRef.current) {
          this.formatRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      });
    }
  }

  handleCopyClick = (index: number) => {
    let courseData = [...this.state.courseData];
    let obj = courseData[index];
    courseData.splice(index + 1, 0, obj);
    this.setState({ courseData });
  }

  handleDeleteClick = (index: number) => {
    let courseData = [...this.state.courseData];
    courseData.splice(index, 1);
    this.setState({ courseData });
  }

  hideFormatOption = () => {
    this.setState({ currentIndex: -1, buttonType: "" });
  }

  handlePadding = (e, key: string) => {
    let courseData = [...this.state.courseData];
    let obj = courseData[this.state.currentIndex];
    obj = { ...obj, [key]: e.target.value };
    courseData[this.state.currentIndex] = obj;
    this.setState({ courseData });
  }

  handleBlockPadding = (e) => {
    let courseData = [...this.state.courseData];
    let obj = courseData[this.state.currentIndex];
    const value = parseInt(e.target.value);
    obj = {...obj, paddingTop: value, paddingBottom: value};
    courseData[this.state.currentIndex] = obj;
    this.setState({ courseData });
  }

  getCurrentContent = () => {
    const { currentObj } = this.state;
    const { type, isMediaUrl, image, video } = currentObj;
    let obj = {
      ...currentObj,
      fileError: undefined,
      urlError: undefined,
    };
    if (type === "Image") {
      obj.mediaUrl = isMediaUrl ? image : undefined;
    } else if (type === "Video") {
      obj.mediaUrl = isMediaUrl ? video : undefined;
    } else if (type === "Carousel") {
      obj.content = obj.content.map((item) => ({
        ...item,
        fileError: undefined,
        urlError: undefined,
        mediaUrl: item.isMediaUrl ? item.media : undefined
      }));
    }
    return obj;
  }

  handleDrawerToggle = () => {
    const courseData = [...this.state.courseData];
    courseData[this.state.currentIndex] = this.getCurrentContent();
    this.setState({
      courseData,
      buttonType: "",
      currentObj: {},
      currentIndex: -1,
      isDrawerOpen: !this.state.isDrawerOpen
    });
  }

  onLessonCreation = (lessonItem: LessonItem, index: number) => {
    this.setState({
      isLessonVisible: true,
      currentLessonId: lessonItem.id,
      lessonTitle: lessonItem.lessonTitle.title,
      lessonAuthorName: this.state.authorName,
      courseData: this.state.lessonList[index].courseData,
    }, () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  onQuizCreation = (lessonItem: LessonItem, index: number) => {
    this.setState({
      currentLessonId: lessonItem.id,
      isQuizVisible: true,
      quizData: this.state.lessonList[index].quizData
    },
    () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    })
  }

  handleQuestionTypeChange = (value, index) => {
    const quizData = [...this.state.quizData];
    quizData[index].type = value;
    this.setState({ quizData });
  };

  handleQuestionChange = (value, index) => {
    const quizData = [...this.state.quizData];
    quizData[index].question = value;
    this.setState({ quizData });
  };

  handleSupportingTextChange = (value, index) => {
    const quizData = [...this.state.quizData];
    quizData[index].supportingText = value;
    this.setState({ quizData });
  };

  handleOptionTextChange = (value, questionIndex, optionIndex) => {
    const quizData = [...this.state.quizData];
    quizData[questionIndex].options[optionIndex] = value;
    this.setState({ quizData });
  };

  handleOptionCorrectChange = (questionIndex, optionIndex) => {
    const quizData = [...this.state.quizData];
    quizData[questionIndex].options[optionIndex].isCorrect = !quizData[questionIndex].options[optionIndex].isCorrect;
    this.setState({ quizData });
  };

  handleAddOption = (questionIndex) => {
    const quizData = [...this.state.quizData];
    quizData[questionIndex].options.push({ text: '', isCorrect: false });
    this.setState({ quizData });
  };

  handleRemoveOption = (questionIndex, optionIndex) => {
    const quizData = [...this.state.quizData];
    quizData[questionIndex].options.splice(optionIndex, 1);
    this.setState({ quizData });
  };

  handleAddNewQuestion = () => {
    const newQuestion = {
      type: 'multiple',  
      question: '',     
      supportingText: '', 
      options: [
        { text: '', isCorrect: false },
        { text: '', isCorrect: false },
        { text: '', isCorrect: false },
      ] 
    };
    this.setState((prevState) => ({
      quizData: [...(prevState.quizData || []), newQuestion]
    }));
  };


  handleShowQuizEditor = () => {
    this.setState({
      isQuizEditorVisibile: true
    })
  }

  handleCloseQuizEditor = () => {
    this.setState({
      isQuizEditorVisibile: false
    })
  }

  onChangeLessonTitle = (event) => {
    let lessonList = [...this.state.lessonList];
    lessonList = lessonList.map((item) => (
      item.id === this.state.currentLessonId
        ? { ...item, lessonTitle: { ...item.lessonTitle, title: event.target.value }}
        : item
    ));
    this.setState({ lessonList, lessonTitle: event.target.value });
  }

  onChangeAuthorName = (event) => {
    this.setState({
      authorName: event.target.value,
      lessonAuthorName: event.target.value,
    });
  }

  onChangeValue = (key: string, value: string) => {
    this.setState({ currentObj: { ...this.state.currentObj, [key]: value } });
  }

  onChangeHeading = (value: string) => {
    this.setState({ currentObj: { ...this.state.currentObj, heading: value } });
  }

  handleUpDownClick = (index: number) => {
    const courseData = [...this.state.courseData];
    [courseData[index],courseData[index-1]] = [courseData[index-1],courseData[index]];
    this.setState({ courseData });
  }

  onPreview = () => {
    this.setState({
      buttonType: "",
      currentObj: {},
      isToolbarVisible: false,
      isPreview: !this.state.isPreview,
    });
  }

  onPlusClick = (index: number) => {
    this.setState({
      isSidebarOpen: true,
      currentIndex: index
    });
  }

  onChangeListValue = (key: string, value: string) => {
    let { content } = this.state.currentObj;
    if (key === "listType") {
      if (value === "ordered") {
        content = content
          .replace('<ul>', '<ol>')
          .replace('</ul>', '</ol>');
      } else {
        content = content
          .replace('<ol>', '<ul>')
          .replace('</ol>', '</ul>');
      }
      this.setState({
        currentObj: {
          ...this.state.currentObj,
          [key]: value,
          content: content,
        }
      });
    } else {
      this.setState(prevState => ({
        currentObj: {
          ...prevState.currentObj,
          [key]: value,
        }
      }));
    }
  }

  handleKeyDown = (key: string) => {
    if (key === 'Enter') {
      this.onImportFromLink();
    }
  }

  onChangeUrl = (url: string) => {
    this.setState(prevState => ({
      currentObj: {
        ...prevState.currentObj,
        mediaUrl: url,
        urlError: ""
      }
    }));
  }

  onRemoveImage = () => {
    this.setState(prevState => ({
      currentObj: {
        ...prevState.currentObj,
        mediaUrl: "",
        fileError: "",
        fileName: "",
        urlError: "",
        image: null,
        isMediaUrl: false,
      }
    }));
  }

  checkImageResolution = (img: React.HTMLImageElement, callback: (isHighResolution: boolean) => void): void => {
    const { type } = this.state.currentObj;
    const isQuote = type === "Quote";
    const isHighResolution = isQuote
      ? img.width >= 30 && img.height >= 30
      : img.width >= 1000 && img.height >= 300;
    callback(isHighResolution);
  }

  doesImageExist = (url: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        this.checkImageResolution(img, (isHighResolution) => {
          this.setState(prevState => ({
            currentObj: {
              ...prevState.currentObj,
              fileError: "",
              fileName: "",
              image: isHighResolution ? url : prevState.currentObj.image,
              isMediaUrl: isHighResolution ? true : prevState.currentObj.isMediaUrl,
              urlError: isHighResolution ? "" : "Image resolution is too low",
            }
          }));
         return resolve(true);
        });
      };
      img.onerror = () => {
        this.setState(prevState => ({
          currentObj: {
            ...prevState.currentObj,
            fileError: "",
            urlError: "Invalid URL or image not fetched"
          }
        }));
        return reject(false);
      };
    });
  }

  onImportFromLink = async () => {
    const url = this.state.currentObj.mediaUrl;
    try {
      await this.doesImageExist(url);
    } catch (err) {
      this.setState(prevState => ({
        currentObj: {
          ...prevState.currentObj,
          fileError: "",
          urlError: "Image not fetched"
        }
      }));
    }
  }

  onCaptureMedia = (key: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const supportedFormats = ['image/jpg', 'image/jpeg', 'image/png'];

    if (!file) return;

    if (supportedFormats.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          this.checkImageResolution(img, (isHighResolution) => {
            if (isHighResolution) {
              this.setState(prevState => ({
                currentObj: {
                  ...prevState.currentObj,
                  mediaUrl: "",
                  fileError: "",
                  urlError: "",
                  isMediaUrl: false,
                  fileName: file.name,
                  [key]: reader.result as string
                }
              }));
            } else {
              this.setState(prevState => ({
                currentObj: {
                  ...prevState.currentObj,
                  urlError: "",
                  fileError: "Image resolution is too low"
                }
              }));
            }
          });
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      this.setState(prevState => ({
        currentObj: {
          ...prevState.currentObj,
          urlError: "",
          fileError: "Only .png, .jpg, .jpeg allowed"
        }
      }));
    }
  }

  handleKeyDownForVideo = (key: string) => {
    if (key === 'Enter') {
      this.onImportVideoFromLink();
    }
  }

  onImportVideoFromLink = () => {
    this.setState(prevState => ({
      currentObj: {
        ...prevState.currentObj,
        fileError: "",
        fileName: "",
        urlError: "",
        video: this.state.currentObj.mediaUrl,
        isMediaUrl: true,
      }
    }));
  }

  onCaptureVideo = (key: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const supportedVideoFormats = ['video/mp4', 'video/webm', 'video/ogg', 'video/x-msvideo', 'video/avi'];

    if (!file) return;

    if (supportedVideoFormats.includes(file.type)) {
      const maxFileSize = 25 * 1024 * 1024;
      if (file.size > maxFileSize) {
        this.setState(prevState => ({
          currentObj: {
            ...prevState.currentObj,
            urlError: "",
            fileError: "Video size exceeds 25 MB",
          }
        }));
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        const video = document.createElement('video');
        video.onloadedmetadata = () => {
          this.setState(prevState => ({
            currentObj: {
              ...prevState.currentObj,
              mediaUrl: "",
              fileError: "",
              urlError: "",
              isMediaUrl: false,
              fileName: file.name,
              [key]: reader.result as string
            }
          }));
        }
        video.onerror = () => {
          this.setState(prevState => ({
            currentObj: {
              ...prevState.currentObj,
              urlError: "",
              fileError: "Invalid video file"
            }
          }));
        };
        video.src = event.target?.result as string;
      };
      reader.readAsDataURL(file);
    } else {
      this.setState(prevState => ({
        currentObj: {
          ...prevState.currentObj,
          urlError: "",
          fileError: "Only .mp4, .webm, .ogg, and .avi formats are allowed"
        }
      }));
    }
  }

  onChangeCarouselHeading = (index: number, key: string, value: string) => {
    this.setState(prevState => {
      const updatedContent = prevState.currentObj.content.map((item, idx) =>
        idx === index ? { ...item, [key]: value } : item
      );
      return {
        currentObj: {
          ...prevState.currentObj,
          content: updatedContent
        }
      };
    });
  }

  onAddCarouselSides = () => {
    this.setState(prevState => ({
      currentObj: {
        ...prevState.currentObj,
        content: [...prevState.currentObj.content, defaultCarouselContent]
      }
    }));
  }

  processCarouselImageFile = (file: File, key: string, updateContent: Function) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        this.checkImageResolution(img, (isHighResolution) => {
          if (isHighResolution) {
            updateContent({
              mediaUrl: "",
              fileError: "",
              urlError: "",
              type: "image",
              isMediaUrl: false,
              fileName: file.name,
              [key]: reader.result as string
            });
          } else {
            const newContent = [...this.state.currentObj.content];
            updateContent({
              urlError: "",
              fileError: "Image resolution is too low"
            })
          }
        });
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  processCarouselVideoFile = (file: File, key: string, updateContent: Function) => {
    const maxFileSize = 25 * 1024 * 1024;
    if (file.size > maxFileSize) {
      updateContent({
        urlError: "",
        fileError: "Video size exceeds 25 MB",
      })
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      const video = document.createElement('video');
      video.onloadedmetadata = () => {
        updateContent({
          mediaUrl: "",
          fileError: "",
          urlError: "",
          isMediaUrl: false,
          type: "video",
          fileName: file.name,
          [key]: reader.result as string
        });
      }
      video.onerror = () => {
        updateContent({
          urlError: "",
          fileError: "Invalid video file"
        });
      };
      video.src = event.target?.result as string;
    };
    reader.readAsDataURL(file);
  }

  onCaptureCarouselMedia = (key: string, index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const imageFormats = ['image/jpg', 'image/jpeg', 'image/png'];
    const videoFormats = ['video/mp4', 'video/webm', 'video/ogg', 'video/x-msvideo', 'video/avi'];

    if (!file) return;

    const updateContent = (updatedFields: {}) => {
      this.setState(prevState => {
        const newContent = [...prevState.currentObj.content];
        newContent[index] = { ...newContent[index], ...updatedFields };
        return {
          currentObj: {
            ...prevState.currentObj,
            content: newContent,
          }
        };
      });
    }

    if (imageFormats.includes(file.type)) {
      this.processCarouselImageFile(file, key, updateContent);
    } else if (videoFormats.includes(file.type)) {
      this.processCarouselVideoFile(file, key, updateContent);
    } else {
      updateContent({
        urlError: "",
        fileError: "Only .png, .jpg, .jpeg, .mp4, .webm, .avi allowed"
      });
    }
  }

  scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight
    });
  };

  componentDidMount() {
    this.setState({ isInitialLoad: false });
    this.handleAddNewQuestion()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.lessonList.length != this.state.lessonList.length) {
      if (this.state.lessonList.length === 0) {
        this.setState({ ...this.state, lessonCreationBar: { title: "", isActive: true } })
        this.scrollToBottom()
      }
    }

    if (!this.state.isInitialLoad && !prevState.lessonCreationBar.isActive && this.state.lessonCreationBar.isActive) {
      this.scrollToBottom();
    }
  }

  addNewLesson = (title: string) => {
    const generatedId = crypto.randomBytes(16).toString('hex');
    if (this.state.lessonList.some(lesson => lesson.lessonTitle?.title === title)) {
      return;
    }

    this.setState({
      lessonCreationBar: { title: "", isActive: false },
      lessonList: [...this.state.lessonList, { lessonTitle: { title, isActiveToChange: false }, id: generatedId, courseData: [], quizData: [] }],
    });

    this.scrollToBottom()
  };

  deleteLesson = (id: number) => {
    this.setState({ lessonList: [...this.state.lessonList.filter((lesson) => lesson.id != id)] })
  }

  setCreationBarActive = () => {
    this.setState({ lessonCreationBar: { ...this.state.lessonCreationBar, isActive: true } });
  }

  onHandleEnterPress = (event, callback) => {
    if (event.key === 'Enter') {
      callback();
    }
  }

  onToggleLessonTypeMenu = (event: React.FormEvent<HTMLInputElement>) => {
    const { clientX: posX, clientY: posY } = event;
    if (this.lessonTypeMenuRef.current) {
      this.lessonTypeMenuRef.current.openMenu(posX, posY);
    }
  }

  onHandleSetLessonTitleActive = (lessonId, value: boolean) => {
    this.setState(prevState => ({
      lessonList: prevState.lessonList.map(lessonItem =>
        lessonItem.id === lessonId
          ? {
            ...lessonItem,
            lessonTitle: {
              ...lessonItem.lessonTitle,
              isActiveToChange: value
            }
          }
          : lessonItem
      )
    }));
  };

  onHandleLessonTitleChange = (event, lessonId) => {
    this.setState(prevState => ({
      lessonList: prevState.lessonList.map(item =>
        item.id === lessonId
          ? {
            ...item,
            lessonTitle: {
              ...item.lessonTitle,
              title: event.target.value
            }
          }
          : item
      )
    }));
  }

  onHandleCourseTitleChange = (event) => {
    this.setState({ courseTitle: event.target.value });
  }

  onHandleAuthorNameTitleChange = (event) => {
    this.setState({ authorName: event.target.value });
  }

  setLessonListType = (type: LessonListType) => {
    this.setState({ lessonListType: type });
    if (this.lessonTypeMenuRef.current) {
      this.lessonTypeMenuRef.current.closeMenu();
    }
  }

  onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) {
      return;
    }

    const reorderedList = Array.from(this.state.lessonList);
    const [removed] = reorderedList.splice(source.index, 1);
    reorderedList.splice(destination.index, 0, removed);
    this.setState({ lessonList: reorderedList });
  };

  onOpenLessonPopupMenu = (event: React.FormEvent<HTMLInputElement>, lessonId: number) => {
    const targetElement = event.currentTarget;
    const { x, y, height } = targetElement.getBoundingClientRect();
    if (this.lessonPopupMenuRefs[lessonId] && this.lessonPopupMenuRefs[lessonId].current) {
      this.lessonPopupMenuRefs[lessonId].current.openMenu(x - 180, y + height + 8)
      this.setState(prevState => ({
        lessonList: prevState.lessonList.map(item => ({
          ...item,
          isPopUpActive: item.id === lessonId
        }))
      }));
    }
  };

  onCloseLessonPopupMenu = () => {
    this.setState({
      lessonList: this.state.lessonList.map(item => ({
        ...item,
        isPopUpActive: false
      }))
    });
  }

  onChangeCourseDescription = (value: string) => {
    this.setState({ description: value });
  }

  onEditorSave = (result: string) => {
    this.setState({description: result});
    if (this.editorMenuRef.current) {
      this.editorMenuRef.current.closeMenu();
    }
  }
  handleImageClick = (e,index:number) => {
    const { courseData } = this.state;
    const containerBounds = this.containerRef.current.getBoundingClientRect();

    const x = e.clientX - containerBounds.left;
    const y = e.clientY - containerBounds.top;

    const newButton = {
      title:`<p style="font-family:'Crimson Text';font-size:16px;font-weight:bold;">Heading of Card</p>`,
        description: `<p style="font-family:'Crimson Text';font-size:14px">This is the subtext that you can edit in the editor</p>`,
        visibleContent:false,
      x: Math.max(0, Math.min(x, containerBounds.width)),
      y: Math.max(0, Math.min(y, containerBounds.height)),
      contentRightSide:true,
      id:`button-${Date.now()}`
    };

    const updatedCourseData = [...courseData];
    const labelGraphicData = { ...updatedCourseData[index] };

    if (labelGraphicData.content.length < 6) {
      labelGraphicData.content = [...labelGraphicData.content, newButton];
      updatedCourseData[index] = labelGraphicData;

      this.setState({ courseData: updatedCourseData });
    }
  };

  handleDragStart = (e, buttonIndex, parentIndex) => {
    const { courseData } = this.state;
  console.log(e)
    const containerBounds = this.containerRef.current.getBoundingClientRect();
    
    const labelGraphicData = courseData[parentIndex];
    const button = labelGraphicData.content[buttonIndex];
    
    const offsetX = e.clientX - containerBounds.left - button.x;
    const offsetY = e.clientY - containerBounds.top - button.y;
    
    this.setState({
      draggingIndex: buttonIndex,
      offset: { x: offsetX, y: offsetY }
    });
  };

  handleDrag = (e, index:number) => {
    const { draggingIndex, courseData, offset } = this.state;
    const updatedCourseData = [...courseData];
    const labelGraphicData = { ...updatedCourseData[index] };
  
    if (draggingIndex === null) return;

    // Calculate new position during drag
    const containerBounds = this.containerRef.current.getBoundingClientRect();
    const x = e.clientX - containerBounds.left - offset.x;
    const y = e.clientY - containerBounds.top - offset.y;
    const buttonRef = document.querySelector(`[data-test-id="draggable-btn-${draggingIndex}"]`);
    const buttonBounds = buttonRef ? buttonRef.getBoundingClientRect() : { width: 0, height: 0 };
  
    // Update button position within container boundaries
    const updatedButtons = [...labelGraphicData.content];
    updatedButtons[draggingIndex] = {
      ...updatedButtons[draggingIndex],
      x: Math.max(0, Math.min(x, containerBounds.width - buttonBounds.width)),
      y: Math.max(0, Math.min(y, containerBounds.height - buttonBounds.height)),
    };
    labelGraphicData.content = updatedButtons;
    updatedCourseData[index] = labelGraphicData;
  
    this.setState({ courseData: updatedCourseData });
  };

  handleDragEnd = (e) => {
    this.setState({ draggingIndex: null });
  };
  handleEditLabelContent = (parentIndex: number, id: string, key: string, valueUpdate: any) => {
    const { courseData } = this.state;
  const updatedCourseData = [...courseData];
  
  // Access the parent and update the specific content item by id
  updatedCourseData[parentIndex] = {
    ...updatedCourseData[parentIndex],
    content: updatedCourseData[parentIndex].content.map(item =>
      item.id === id ? { ...item, [key]: valueUpdate } : item
    )
  };

  this.setState({ courseData: updatedCourseData });
  };
  handleEditContent = (key:string,value:string, id: string)=>{
    const currentObj = { ...this.state.currentObj };
  
    const index = currentObj.content.findIndex(item => item.id === id);
  
    if (index !== -1) {
      const updatedContent = [...currentObj.content];
      updatedContent[index] = {
        ...updatedContent[index],
        [key]: value
      };
  
      currentObj.content = updatedContent;
      this.setState({ currentObj });
    }
  }
  deleteContentItem = (id: string) => {
    const currentObj = { ...this.state.currentObj };
    
    const updatedContent = currentObj.content.filter(item => item.id !== id);
  
    currentObj.content = updatedContent;
    this.setState({ currentObj });
  };
  handleAddNewBtn = () => {
    const { currentObj } = this.state;

    const newButton = {
      contentRightSide:true,
      id:`button-${Date.now()}`,
      title:`<p style="font-family:'Crimson Text';font-size:16px;font-weight:bold;">Heading of Card</p>`,
        description: `<p style="font-family:'Crimson Text';font-size:14px">This is the subtext that you can edit in the editor</p>`,
        visibleContent:false,
      x: 100,
      y: 100,
    };

    const updatedObj = {...currentObj};

    if (updatedObj.content.length < 6) {
      updatedObj.content = [...updatedObj.content, newButton];

      this.setState({ currentObj: updatedObj });
    }
  };
  handleFlipCard = (index:number,parentIndex:number,valueUpdate:boolean)=>{
    const { courseData } = this.state;
    const updatedCourseData = [...courseData];
    
    const flashCardData = { ...updatedCourseData[parentIndex] };
    
    const updatedContent = [...flashCardData.cards];
    updatedContent[index] = {
      ...updatedContent[index],
      flipped: valueUpdate
    };
  
    flashCardData.cards = updatedContent;
  
    updatedCourseData[parentIndex] = flashCardData;
  
    this.setState({ courseData: updatedCourseData });
  }
  handleEditCardContent = (key:string,value:string, id: number)=>{
    const currentObj = { ...this.state.currentObj };
  const updatedCards = currentObj.cards.map((card) => {
    if (card.id === id) {
      return {
        ...card,
        [key]: value,
      };
    }
    return card;
  });

  this.setState({ currentObj: { ...currentObj, cards: updatedCards } });
  }
  handleDeleteCardContent = (id:string)=>{
    const currentObj = { ...this.state.currentObj };
    const updatedCards = [...currentObj.cards].filter(card=>card.id!== id);
    currentObj.cards=updatedCards

    this.setState({ currentObj });
  }
  handleAddNewCard=()=>{
    const { currentObj } = this.state;

    const newCard = {
      id:`card-${Date.now()}`,
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card</p>`,
        back:`<p style="font-family:'Crimson Text'font-size:14px">Back of Card</p>`,
        flipped:false
    };

    const updatedObj = {...currentObj};

    if (updatedObj.cards.length < 6) {
      updatedObj.cards = [...updatedObj.cards, newCard];

      this.setState({ currentObj: updatedObj });
    }
  }
  handleTouchStart = (e, buttonIndex, parentIndex) => {
    const { courseData } = this.state;
    const touch = e.touches[0];
    
    const containerBounds = this.containerRef.current.getBoundingClientRect();
    const labelGraphicData = courseData[parentIndex];
    const button = labelGraphicData.content[buttonIndex];

    const offsetX = touch.clientX - containerBounds.left - button.x;
    const offsetY = touch.clientY - containerBounds.top - button.y;

    this.setState({
      draggingIndex: buttonIndex,
      currentParentIndex:parentIndex,
      offset: { x: offsetX, y: offsetY },
    });

    document.addEventListener('touchmove', this.handleTouchMove);
    document.addEventListener('touchend', this.handleTouchEnd);
  };

handleTouchMove = (e) => {
    const { draggingIndex, courseData, offset } = this.state;
    const updatedCourseData = [...courseData];
    const labelGraphicData = { ...updatedCourseData[this.state.currentParentIndex] };

    if (draggingIndex === null) return;

    const touch = e.touches[0];
    const containerBounds = this.containerRef.current.getBoundingClientRect();
    const x = touch.clientX - containerBounds.left - offset.x;
    const y = touch.clientY - containerBounds.top - offset.y;
    const buttonRef = document.querySelector(`[data-test-id="draggable-btn-${draggingIndex}"]`);
    const buttonBounds = buttonRef ? buttonRef.getBoundingClientRect() : { width: 0, height: 0 };
    const updatedButtons = [...labelGraphicData.content];
    updatedButtons[draggingIndex] = {
      ...updatedButtons[draggingIndex],
      x: Math.max(0, Math.min(x, containerBounds.width -  buttonBounds.width)),
      y: Math.max(0, Math.min(y, containerBounds.height - buttonBounds.height)),
    };

    labelGraphicData.content = updatedButtons;
    updatedCourseData[this.state.currentParentIndex] = labelGraphicData;

    this.setState({ courseData: updatedCourseData });
  };

handleTouchEnd = () => {
    this.setState({ draggingIndex: null });
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('touchend', this.handleTouchEnd);
  };
  addRow = (position = "after") => {
    const { selectedRowIndex, currentObj } = this.state;
    if (selectedRowIndex === null) return;

    // Validate row count
    const rowCount = currentObj.content[0].table_rows_attributes.length;
    if (rowCount >= 5) {
      alert(`Maximum of ${5} rows reached`);
      return;
    }

    const headers = Object.keys(currentObj.content[0]).filter(key => key.startsWith("heading"));
    const newRow = {};
    headers.forEach((header, i) => {
      newRow[`column${i + 1}`] = `<p></p>New Row Column ${i + 1}</p>`;
    });

    const updatedContent = [...currentObj.content];
    const newIndex = position === "after" ? selectedRowIndex + 1 : selectedRowIndex;
    updatedContent[0].table_rows_attributes.splice(newIndex, 0, newRow);

    this.setState({
      currentObj: {
        ...currentObj,
        content: updatedContent
      }
    });
  };
  addColumn = ( position = "after") => {
    const { selectedColumnIndex, currentObj} = this.state;
    if (selectedColumnIndex === null) return;

    // Validate column count
    const columnCount = Object.keys(currentObj.content[0]).filter(key => key.startsWith("heading")).length;
    if (columnCount >= 4) {
      alert(`Maximum of ${4} columns reached`);
      return;
    }

    const newColumnIndex = columnCount + 1;
    const newHeader = `<p>New Heading ${newColumnIndex}</p>`;
    const updatedContent = [...currentObj.content];

    // Insert new header
    const newHeaderIndex = position === "after" ? selectedColumnIndex + 1 : selectedColumnIndex;
    updatedContent[0] = Object.keys(updatedContent[0])
      .reduce((acc, key, i) => {
        if (key.startsWith("heading") && i === newHeaderIndex) {
          acc[`heading${newColumnIndex}`] = newHeader;
        }
        acc[key] = updatedContent[0][key];
        return acc;
      }, {});

    // Update rows with new column
    updatedContent[0].table_rows_attributes = updatedContent[0].table_rows_attributes.map((row) => {
      const newRow = { ...row };
      newRow[`column${newColumnIndex}`] = `Row New Column ${newColumnIndex}`;
      return newRow;
    });

    this.setState({
      currentObj: {
        ...currentObj,
        content: updatedContent,
        selectedColumnIndex: null
      }
    });
  };
  handleCellSelect = (rowIndex, colIndex) => {
    this.setState({
      selectedRowIndex: rowIndex,
      selectedColumnIndex: colIndex
    });
  };
  deleteColumn = () => {
    const { selectedColumnIndex, currentObj } = this.state;
    if (selectedColumnIndex === null) return;

    const columnToDelete = `column${selectedColumnIndex + 1}`;
    const updatedContent = [...currentObj.content];

    // Remove the header
    delete updatedContent[0][`heading${selectedColumnIndex + 1}`];

    // Remove the column from each row
    updatedContent[0].table_rows_attributes = updatedContent[0].table_rows_attributes.map((row) => {
      const newRow = { ...row };
      delete newRow[columnToDelete];
      return newRow;
    });

    this.setState({
      currentObj: {
        ...currentObj,
        content: updatedContent,
        selectedColumnIndex: null
      }
    });
  };
  deleteRow = () => {
    const { selectedRowIndex, currentObj } = this.state;
    if (selectedRowIndex === null) return;

    const updatedContent = [...currentObj.content];
    updatedContent[0].table_rows_attributes.splice(selectedRowIndex, 1);

    this.setState({
      currentObj: {
        ...currentObj,
        content: updatedContent,
        selectedRowIndex: null
      }
    });
  };
  handleTableValueChange =   (value:string, rowIndex:number, colIndex:number, isHeader:boolean = false) => {
    const updatedContent = [...this.state.currentObj.content];
    const tableData = updatedContent[0];

    if (isHeader) {
      // Update header
      const headers = Object.keys(tableData).filter(key => key.startsWith("heading"));
      tableData[headers[colIndex]] = value;
    } else {
      // Update cell in rows
      tableData.table_rows_attributes[rowIndex][`column${colIndex + 1}`] = value;
    }

    this.setState({
      currentObj: {
        ...this.state.currentObj,
        content: updatedContent
      }
    });
  };
  // Customizable Area End
}
