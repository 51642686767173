// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { apiCall } from "../../../components/src/ApiCall.web";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  isAdmin?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string;
  error: string;
  isOpen: boolean;
  loading: boolean;
  isEmailDisable: boolean;
  isMobile: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: string;
  // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resetPasswordApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      email: "",
      error: "",
      isOpen: false,
      loading: false,
      isMobile: window.innerWidth <= 768,
      isEmailDisable: false,
    };
    // Customizable Area End
  }

  async componentDidMount() {
    window.addEventListener('resize', this.handleCheckScreenWidth);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.resetPasswordApiCallId) {
          if (responseJson.message) {
            this.setState({ isOpen: true });
          } else if (
            responseJson.errors
            && Array.isArray(responseJson.errors)
            && typeof responseJson.errors[0] === 'string') {
            this.setState({ error: responseJson.errors[0] });
          } else if (responseJson.status === 500) {
            toast.error(responseJson.error);
          }
          this.setState({ loading: false });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleCheckScreenWidth = () => {
    this.setState(prev => ({ ...prev, isMobile: window.innerWidth <= 768 }));
  };

  onRegistrationPage = () => {
    if(this.props.isAdmin) {
      this.props.navigation.navigate("AdminLogin");
    } else {
      this.props.navigation.navigate("EmailAccountRegistration");
    }
  }

  handleClose = (e: any) => {
    this.setState({ isOpen: false, isEmailDisable: true });
  };

  resetPassword = async () => {
    this.setState({ loading: true });
    this.resetPasswordApiCallId = await apiCall({
      method: "POST",
      contentType: "application/json",
      endPoint: this.props.isAdmin ? `bx_block_admin/passwords/forgot_password` : `/passwords/forgot_password`,
      body: {
        "email": this.state.email?.toLowerCase(),
      }
    });
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleCheckScreenWidth);
  }

  // Customizable Area End
}