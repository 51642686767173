import { Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import TextStyle from '@tiptap/extension-text-style';
import FontFamily from '@tiptap/extension-font-family';
import FontSize from 'tiptap-extension-font-size';
import Color from '@tiptap/extension-color';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import Highlight from '@tiptap/extension-highlight';
import Strike from '@tiptap/extension-strike';
import ListItem from '@tiptap/extension-list-item';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import TextAlign from '@tiptap/extension-text-align';
import { Position } from './HelpCenterAdminPartTypes';

export type EditorTypes = 'title' | 'content' | 'description' | 'privacyPolicy' | 'termsAndCondition';

export const initEditor = (
	type: EditorTypes,
	onUpdate: (type: EditorTypes, editor: Editor) => void,
	onSelectionUpdate: (type: EditorTypes, editor: Editor) => void,
	onBlur: (editor: Editor) => void,
	placeholder?: string,
) => {
	return new Editor({
		extensions: [
			StarterKit.configure({ heading: { levels: [1] } }),
			Placeholder.configure({
				placeholder: `${placeholder}`,
			}),
			TextStyle,
			FontFamily,
			FontSize,
			Color,
			Highlight,
			Bold,
			Italic,
			Underline,
			Strike,
			ListItem,
			BulletList,
			OrderedList,
			TextAlign.configure({ types: ['heading', 'paragraph'] }),
		],
		content: '',
		onUpdate: ({ editor }) => onUpdate(type, editor),
		onSelectionUpdate: ({ editor }) => onSelectionUpdate(type, editor),
		onBlur: ({ editor }) => onBlur(editor),
	});
};

export const updateToolbarPosition = (
	editor: Editor,
	toolbarRef: React.RefObject<HTMLDivElement>,
	setPosition: (position: Position) => void,
	setIsEditing: (isEditing: boolean) => void
) => {
	const selection = editor.state.selection;
	if (selection && !selection.empty) {
		const { from } = selection;
		const coords = editor.view.coordsAtPos(from);
		const toolbarWidth = toolbarRef.current?.offsetWidth || 0;
		const windowWidth = window.innerWidth;
		let newLeft = coords.left;
		if (((coords.left + toolbarWidth / 2) - 30) > windowWidth) {
			newLeft = windowWidth - (toolbarWidth / 2) - 30;
		}
		if (coords.left < 0) {
			newLeft = 10;
		}

		setPosition({
			top: coords.top + window.scrollY + 40,
			left: newLeft,
		});
	} else {
		setIsEditing(false);
	}
};

export const handleEditorBlur = (
	editor: Editor,
	defaultContent: string,
	updateContent: (content: string) => void
) => {
	if (editor.getText().trim() === '') {
		editor.commands.setContent(defaultContent);
		updateContent(defaultContent);
	}
};

export const handleOutsideEditorClick = (
	event: MouseEvent,
	editorRefs: React.RefObject<HTMLDivElement>[],
	toolbarRef: React.RefObject<HTMLDivElement>,
	onOutsideClick: () => void
) => {
	const clickedOutside = editorRefs.every(
		(ref) => !ref.current?.contains(event.target as Node)
	) || !toolbarRef.current?.contains(event.target as Node);

	if (clickedOutside) {
		onOutsideClick();
	}
};