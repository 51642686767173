import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import Sidebar from '../../../components/src/sidebar/Sidebar.web';
import { logOutIcon } from "./assets";
import { Close } from '@mui/icons-material';
import {
  Box,
  Typography as MuiTypography,
  styled,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  TextField,
  Button as MuiButton
} from '@mui/material';
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box sx={webStyles.mainWrapper}>
          <Box sx={webStyles.mainBlock}>
            <Sidebar current="admin-settings" />
            <div style={{
              height: '100vh',
              width: '100vw',
              padding: '2rem 10rem'
            }}>
              <div>
                <h3>Account settings</h3>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    columnGap:'8rem',
                    marginBottom:'1rem'
                  }}
                >
                  <MuiTypography>PERSONAL DETAILS</MuiTypography>
                  <div style={{width:'60%'}}>
                    <TextWrapper>
                      <TextHeading >First Name</TextHeading>
                      <Text>{this.state.firstName}</Text>
                    </TextWrapper>
                    <TextWrapper >
                      <TextHeading >Last Name</TextHeading>
                      <Text>{this.state.lastName}</Text>
                    </TextWrapper>
                    <TextWrapper >
                      <TextHeading >Email</TextHeading>
                      <Text>{this.state.email}</Text>
                    </TextWrapper>
                    

                    <div style={{display:'flex',justifyContent:'space-between'}}>
                      <div>
                      <TextHeading>Password</TextHeading>
                      <TextHeading>••••••••</TextHeading>
                      </div>
                      <TextHeading data-test-id="change-password" onClick={this.showChangePasswordDialog} style={{cursor:'pointer'}}>Change Password</TextHeading>
                    </div>
                    
                  </div>

                </div>
                <Divider/>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    columnGap:'8rem',
                    marginTop:'1rem'
                  }}
                >
                  <MuiTypography>ACCOUNT ACTIONS</MuiTypography>
                  <div data-test-id="log-out-btn" onClick={this.onLogout} style={{width:'60%',display:'flex',cursor:'pointer'}}>
                    <img src={logOutIcon} height={20} width={20}  alt="Log-Out-Icon"/>
                    <TextHeading>Log out</TextHeading>
                  </div>  

                </div>
              </div>
              
            </div>
          </Box>
        </Box>


        <Box sx={webStyles.footerBlock}>
          <Box sx={webStyles.leftFooter}>
            <Box>Terms and Conditions</Box>
            <Box>Pravicy Policy</Box>
            <Box></Box>
          </Box>
          <Box>
            <Box>© 2024 All Rights Reserved</Box>
          </Box>
        </Box>


        <StyledDialog
          style={{borderBottomRightRadius:'10%'}}
          fullWidth
          open={this.state.showChangePasswordDiallog}
        >
          <StyledDialogTitle >
            <MuiTypography variant="h6">Change Password</MuiTypography>
            <IconButton
              onClick={this.closeChangePasswordDialog}
              data-test-id="changepassword-close"
              aria-label="close"
              className="MuiIconButton-root">
              <Close />
            </IconButton>
          </StyledDialogTitle>
          <DialogContent dividers>
            <MuiTypography style={{marginBottom:'1rem'}}>Your new password should be different from previous one</MuiTypography>
            <CommonInputLable>Current password</CommonInputLable>
            <CommonTextField
              data-test-id="current-pass"
              onChange={(event) => this.handleCurrentPasswordChange(event)}
              value={this.state.currentPassword}
              autoComplete="new-password"
              style={{ marginBottom: '1rem' }}
              fullWidth
              variant="outlined"
              type="password"
              inputProps={{
                autoComplete: 'new-password'
              }}
              
            />
            <CommonInputLable>Create new password</CommonInputLable>
            <CommonTextField
              data-test-id="password"
              onChange={(event) => this.handlePasswordChange(event)}
              value={this.state.password}
              autoComplete="new-password"
              style={{ marginBottom: '1rem' }}
              fullWidth
              variant="outlined"
              type="password"
              inputProps={{
                autoComplete: 'new-password'
              }}
              error={!!this.state.passwordError}
              helperText={this.state.passwordError}
            />
            <CommonInputLable>Confirm password</CommonInputLable>
            <CommonTextField
              data-test-id='confirm-pass'
              onChange={(event) => this.handleConfirmPasswordChange(event)}
              value={this.state.confirmPassword}
              autoComplete=""
              style={{ marginBottom: '1rem' }}
              fullWidth
              variant="outlined"
              type="password"
              inputProps={{
                autoComplete: 'off'
             }}
             error={!!this.state.confirmPasswordError}
             helperText={this.state.confirmPasswordError}
            />
          </DialogContent>

          <StyledDialogActions>
            <LightButton autoFocus onClick={this.closeChangePasswordDialog} color="primary">
              Cancel
            </LightButton>
            <DarkButton data-test-id="change-password-btn" onClick={this.changePasswordApiCall} autoFocus color="primary">
              Done
            </DarkButton>
          </StyledDialogActions>
        </StyledDialog>
      </>
     
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root':{
    borderBottomRightRadius:'30px'
  }
})
const CommonTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px'
  },
  '& .MuiOutlinedInput-input': {
    padding: '14px 14px'
  }
})
const CommonInputLable = styled(InputLabel)({
  marginBottom: '5px',
  color: '#334155',
  fontWeight: 500
})
const StyledDialogActions = styled(DialogActions)({
  margin: 0,
  padding: '16px 25px',
});
const LightButton = styled(MuiButton)({
  backgroundColor: '#d7d7d7',
  color: 'black',
  textTransform: 'none',
  padding: '10px 20px',
  fontWeight: 'bold',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: '#d7d7d7',
    color: 'black',
  },
})
const DarkButton = styled(MuiButton)({
  backgroundColor: 'black',
  color: 'white',
  textTransform: 'none',
  padding: '10px 20px',
  fontWeight: 'bold',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: 'black',
    color: 'white',
  },
})
const StyledDialogTitle = styled(DialogTitle)({
  margin: 0,
  padding: '16px 25px',
  position: 'relative',
  '& .MuiIconButton-root': {
    position: 'absolute',
    right: '8px',
    top: '8px',
    color: '#757575',
  },
});

const TextHeading = styled(MuiTypography)({
  marginBottom:'5px',
  fontWeight:"bold",
  color:'#334155',
  size:'14px',
  fontFamily:'Heebo'
})
const TextWrapper = styled('div')({
  marginBottom:'1rem',
})
const Text = styled(MuiTypography)({
  color:'#94A3B8',
  fontSize:'16px',
  fontFamily:'Heebo'
})
const webStyles = {
  mainBlock: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    backgroundColor: '#f6f6f6',
  },
  mainWrapper: {
    flex: 1,
    flexDirection: 'col',
    backgroundColor: '#f6f6f6'
  },
  footerBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '24px 52px 24px 52px',
    backgroundColor: '#1C1C1C',
    color: '#fff',
  },
  leftFooter: {
    display: 'flex',
    gap: '24px',
    flexGrow: 1
  },
}


// Customizable Area End
