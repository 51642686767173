import React from "react";
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

import ContactusController, { Props } from "./ContactusController";

// Customizable Area Start
import { styled } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  TablePagination,
  InputBase,
  TextField,
  Dialog, DialogTitle, DialogContent, DialogActions, FormLabel, Divider, IconButton, Popover
} from "@mui/material";
import { MoreVert, FilterList, KeyboardArrowDown } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Sidebar from "./../../../components/src/sidebar/Sidebar.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: 'white', 
  '&:hover': {
    backgroundColor: '#F0F0F0'
  },
}));
// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }
// Customizable Area Start
  renderCategoriesDropdown = (currentItem:any) => {
    return(
    <Select
      labelId={`category-label-${currentItem.id}`}
      id={`select` + currentItem.id}
      key={`select` + currentItem.id}
      label={'category'}
      value={this.getCategoryVal(currentItem)}
      data-test-id="changeCategory"
      renderValue={(value) => value}
      onChange={(e) => this.onChangeCategory(currentItem, e.target.value)}
      sx={{
        minWidth:'180px',
        '& .MuiSelect-select': {
          '&:focus': {
            outline: 'none',
            border: 'none',
          },
        },
        '&:focus-visible': {
          outline: 'none',
        },
      }}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: '300px',
            overflowY: 'auto',
            backgroundColor: '#FFFFF'
          },
        },
      }}
    >
      <CustomMenuItem value={'Category'} sx={styles.menuItemContainer}><Box sx={styles.categoryText}>{'Category'}</Box></CustomMenuItem>
      {this.state.availableCategories?.map((item: any) =>
        <CustomMenuItem value={item.attributes.name} sx={styles.menuItemContainer}><Box>{item.attributes.name}</Box>
          < Box><IconButton
            aria-label="delete"
            data-test-id="delCategory"
            onClick={(e) => this.onDeleteCategory(e, item)}
          >
            <DeleteOutlineIcon />
          </IconButton></Box></CustomMenuItem>
      )}
      <MenuItem value="addNew">
        <Box data-test-id="changeCategory" onClick={(e) => this.onChangeCategory('', "", true)} sx={styles.newCategoryText}>Add category</Box>< Box></Box></MenuItem>

    </Select>)
  }

  renderNewCategoryInput = () => {
    return (
      <TextField
        autoFocus
        label='Enter new category name'
        margin="dense"
        type="text"
        fullWidth
        data-test-id="nameChange"
        value={this.state.newCategoryName}
        onChange={(e) => this.handleCategoryNameChange(e.target.value)}
      />
    )
  }
// Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box sx={styles.mainWrapper}>
            <Box sx={styles.mainBlock}>
              <Sidebar navigation={this.props.navigation} current='contact_us_queries' />

              <Box sx={styles.pageContent} data-testid="pageContent">
                <Box sx={styles.contentHeader}>
                  <Box sx={styles.navigation}>
                    <Box sx={styles.breadcrumbButton}>Contact us Queries</Box>
                  </Box>
                </Box>

                <Box sx={styles.selectOptions}>
                  <Box sx={{ display: 'flex', flexDireaction: 'row' }}>  {this.showHeaderActionButtons() ? <><Box fontStyle={{ fontSize: '16px', fontWeight: '400' }}>
                    <Checkbox
                      checked={true}
                      data-test-id='btnUnselect'
                      onChange={() => this.unSelectAllQueries()}
                      color="info"
                    />
                    {this.getItemsSelectedString()}</Box>
                    <Box sx={styles.markReadBox} data-test-id='btnMark' onClick={() => this.markAsReadUnread(true)}>Mark as read</Box>
                    <Box sx={styles.deleteBox} data-test-id='btnDelete' onClick={() => this.deleteQueries()}>Delete</Box>
                  </>
                    : null} </Box>
                  <Box sx={styles.headerOptions} >
                    <Box sx={styles.filterContainer}>

                      <Box>Filter</Box>
                      <IconButton data-test-id='btnFilter' onClick={(e) => this.handlePopoverClick(e, 'filter')}>
                        <FilterList />
                      </IconButton>
                      <Popover
                        key={'filter_popover'}
                        data-test-id='btnPopover'
                        id={this.filterPopoverId()}
                        style={{ boxShadow: 'none' }}
                        open={this.isFilterPopover()}
                        anchorEl={this.state.anchorElFilter}
                        onClose={() => this.handlePopoverClose()}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        {this.filterKeys.map((item, index) => (
                          <MenuItem key={'filterId' + index} data-test-id='btnOptions' onClick={() => this.handleFilter(item)}>{item.label}</MenuItem>)
                        )}

                      </Popover>
                    </Box>
                    <Box sx={styles.sortByRow} >
                      <Box>Sort by:</Box>
                      <Box data-test-id='btnSort' sx={styles.relevText} onClick={(e) => this.handlePopoverClick(e, 'sort')}>{this.state.currentSortKey}</Box>
                      <Box data-test-id='btnSort' onClick={(e) => this.handlePopoverClick(e, 'sort')}><KeyboardArrowDown /></Box>
                    </Box>
                    <Box>

                      <Popover
                        data-test-id='btnPopover'
                        key={'sort_popover'}
                        id={this.sortPopoverId()}
                        style={{ boxShadow: 'none' }}
                        open={this.isSortPopover()}
                        anchorEl={this.state.anchorElSort}
                        onClose={() => this.handlePopoverClose()}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        {this.sortKeys.map((item, index) => (
                          <MenuItem key={'sortId' + index} data-test-id='btnOptions' onClick={() => this.handleSort(item)}>{item.label}</MenuItem>)
                        )}
                      </Popover>
                    </Box>
                  </Box>
                </Box>
                <Box sx={styles.tableContainer}>

                  <TableContainer component={Paper} sx={{ overflowX: 'scroll' }}>
                    <Table sx={{
                      borderCollapse: 'collapse',
                      border: 'none',
                      backgroundColor: '#f6f6f6',
                      boxShadow: 'none'
                    }
                    }>
                      <TableHead>

                        <TableRow>
                          <TableCell sx={styles.cellHeader}></TableCell>
                          <TableCell sx={styles.cellHeader}><Box sx={styles.headerText}>Query</Box></TableCell>
                          <TableCell sx={styles.cellHeader}><Box sx={styles.headerText}>Category</Box></TableCell>
                          <TableCell sx={styles.cellHeader}><Box sx={styles.headerText}>Sender</Box></TableCell>
                          <TableCell sx={styles.cellHeader}><Box sx={styles.headerText}>Client Company</Box></TableCell>
                          <TableCell sx={styles.cellHeader}><Box sx={styles.headerText}>Date</Box></TableCell>
                          <TableCell sx={styles.cellHeader}><Box sx={styles.headerText}>Status</Box></TableCell>
                          <TableCell sx={styles.cellHeader}></TableCell>
                          <TableCell sx={styles.cellHeader}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!this.state.queriesToRender?.length ? (
                          <TableRow>
                            <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                              <Typography variant="h6" color="textSecondary">
                                No Data Found
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : <>
                          {this.state.queriesToRender?.slice(this.state.page * 10, this.state.page * 10 + 10).map((row, index) => (
                            <> <TableRow key={row.id} sx={{ borderBottom: 'none', backgroundColor: this.getBGColor(row)}}>
                              <TableCell sx={styles.cellWrapper}>
                                <Checkbox
                                  checked={this.isSelectedRow(row.id)}
                                  onChange={() => this.setSelectedQueries(row.id, index)}
                                  color="info"
                                />
                              </TableCell>
                              <TableCell sx={styles.cellWrapper}><Box sx={styles.cellContainer}>
                                <Box sx={{ display: 'fex', flexDirection: 'row' }}><Box sx={styles.name}>{row.attributes.subject}</Box> {row.attributes?.replied ? <Typography sx={styles.repliedBox}>REPLIED</Typography> : null}</Box>
                                <Box sx={styles.descriptionContainer}>
                                  <Typography
                                    variant="body1"
                                    sx={styles.descriptionText}
                                  >
                                    {row.attributes.description}
                                  </Typography>
                                </Box></Box></TableCell>
                              <TableCell sx={styles.cellWrapper}>
                                <Box>
                                  {this.renderCategoriesDropdown(row)}
                                </Box>
                              </TableCell>
                              <TableCell sx={styles.cellWrapper}><Box sx={styles.cellContainer}><Box sx={styles.name}>{row.attributes.name}</Box><Box sx={styles.descriptionText}>{row.attributes.email}</Box></Box></TableCell>
                              <TableCell sx={styles.cellWrapper}><Box sx={styles.name}>{row.attributes.company}</Box></TableCell>
                              <TableCell sx={styles.cellWrapper}><Box sx={styles.name}>{this.formatDate(row.attributes.created_at)}</Box></TableCell>
                              <TableCell sx={styles.cellWrapper}>
                                <FormControl fullWidth variant="outlined" sx={{ border: 'none', borderWidth: '0px' }}>
                                  <Select
                                    labelId={`status-label-${row.id}`}
                                    value={row.attributes.status}
                                    data-test-id="changeCategorySelect"
                                    onChange={(e) => this.updateStatus(row.id, e.target.value)}
                                    sx={this.isCompletedQuery(row) ? styles.statusContainerSuccess : styles.statusContainer}
                                    label=""
                                  >
                                    <MenuItem sx={{ fontSize: '10px' }} value="in_progress">INPROGRESS</MenuItem>
                                    <MenuItem sx={{ fontSize: '10px' }} value="pending">PENDING</MenuItem>
                                    <MenuItem sx={{ fontSize: '10px' }} value="completed">COMPLETED</MenuItem>
                                  </Select>
                                </FormControl></TableCell>
                              <TableCell sx={styles.cellWrapper}><Box data-test-id='btnReply' sx={styles.replyContainer} onClick={() => this.openModal(row)}> <Box sx={styles.replyText}>Reply</Box></Box></TableCell>
                              <TableCell sx={styles.cellWrapper} key={'more_' + row.id} >
                                <Box>
                                  <IconButton key={'btn-more' + row.id} data-test-id='btnMore' onClick={(e) => this.handlePopoverClick(e, 'more', row)}
                                    sx={{
                                      borderRadius: '50%',
                                      width: 40,
                                      height: 40,
                                      backgroundColor: '#F0F0F0',
                                    }}
                                  >
                                    <MoreVert />
                                  </IconButton>
                                  <Popover
                                    data-test-id='btnPopover'
                                    key={'more_popover' + row.id}
                                    id={this.morePopoverId() + row.id}
                                    style={{ boxShadow: 'none' }}
                                    open={this.isMorePopover()}
                                    anchorEl={this.state.anchorEl}
                                    onClose={() => this.handlePopoverClose()}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <MenuItem key={'action_a'} data-test-id='btnOptions' onClick={() => this.openModal(row)}>View</MenuItem>
                                      <MenuItem key={'action_b'} data-test-id='btnOptions' onClick={() => this.markAsReadUnread(true, false, row.id)}>Mark as read</MenuItem>
                                      <MenuItem key={'action_c'} data-test-id='btnOptions' onClick={() => this.markAsReadUnread(false, false, row.id)}>Mark as unread</MenuItem>
                                      <MenuItem key={'action_c'} data-test-id='btnOptions' onClick={() => this.deleteQueries(false, this.state.currentMoreItem?.id)}><Box sx={{ color: '#DC2626' }}>Delete</Box></MenuItem>
                                    </Typography>
                                  </Popover>
                                </Box>
                              </TableCell>
                            </TableRow>
                              <TableRow>
                                <TableCell sx={{ padding: '0px', border: 'none', height: '8px' }} />
                              </TableRow>
                            </>
                          ))}</>}
                      </TableBody>

                    </Table>
                  </TableContainer>
                </Box>
                <Dialog open={this.isOpenNewCatDialog()} onClose={() => { }}>
                  <DialogTitle>Add New Category</DialogTitle>
                  <DialogContent>
                    {this.renderNewCategoryInput()}
                  </DialogContent>
                  <DialogActions>
                    <CancelButton data-test-id="btnClose" onClick={this.toggleAddNewCategoryModal}>
                      Cancel
                    </CancelButton>
                    <DoneButton
                      type="submit"
                      data-test-id="btnClose"
                      onClick={() => this.addNewCategory()}
                    >
                      Add
                    </DoneButton>
                  </DialogActions>
                </Dialog>

                <CustomDialog
                  data-test-id="dialogContainer"
                  open={this.isReplyModalOpen()}
                  scroll="body"
                  sx={{ paddingTop: '10px', paddingBottom: '10px' }}
                  onClose={() => this.closeModal()}
                >
                  <DialogTitle className="dialog-title">
                    <ArrowBackIcon data-test-id="btnClose" className="arrow-icon" onClick={() => this.closeModal()} />
                    <Typography className="heading">Reply to: {this.state.currentModalItem?.name}</Typography>
                    <CloseIcon className="close-icon" data-test-id="btnClose" onClick={() => this.closeModal()} />
                  </DialogTitle>
                  <DialogContent className="content">
                    <Box sx={styles.modalDesc}><Box sx={styles.modalText}>{this.state.currentModalItem.attributes?.description}</Box></Box>

                    <Box sx={[styles.modalItemCol, { marginTop: '10px' }]}>
                      <Box fontStyle={styles.modalName}>{this.state.currentModalItem.attributes?.name}</Box><Box fontStyle={styles.modalName}>{this.state.currentModalItem.attributes?.company}</Box>
                    </Box>
                    <Box sx={styles.modalItemCol}>
                      <Box fontStyle={styles.modalSubInfo}>{this.state.currentModalItem.attributes?.job_title}</Box><Box fontStyle={styles.modalSubInfo}>{this.state.currentModalItem.attributes?.email}</Box>
                    </Box>
                    <Divider sx={{ marginTop: '20px' }} flexItem />

                    <Box sx={{ paddingTop: '20px' }}>
                      <FormControl fullWidth variant="outlined" sx={{}}>
                        <FormLabel component="legend">Category</FormLabel>
                        {this.renderCategoriesDropdown(this.state.currentModalItem)}
                        {this.state.showNewCategoryInput && <Box sx={{ display: 'flex', flex: '1', flexDirection: 'row' }}> {this.renderNewCategoryInput()}
                        <DoneButton
                          type="submit"
                          data-test-id="btnClose"
                          sx={{ margin: '10px' }}
                          onClick={() => this.addNewCategory()}
                        >
                            Add
                          </DoneButton></Box>}
                      </FormControl>
                    </Box><Box sx={{ display: 'flex', flex: '1', flexDirection: 'row' }}>
                      <Box>{this.isReplied() ? 'Replied' : ''}</Box>
                    </Box>
                    <Box sx={{ marginTop: '20px' }}>
                      <FormControl fullWidth variant="outlined" >
                        <FormLabel component="legend">Your reply</FormLabel>
                        {!this.state.isReplied ?
                          <TextField
                            variant="outlined"
                            value={this.state.replyText}
                            data-test-id="nameChange"
                            onChange={(e: any) => this.onChangeReplyText(e)}
                            multiline
                            rows={4}

                            sx={{ minHeight: '160px' }}
                          /> : <p>{this.state.replyText}</p>
                        }</FormControl>
                    </Box>
                  </DialogContent>
                  {this.isReplied() ? null : <DialogActions className="dialog-action" sx={{ marginBottom: '10px' }}>
                    <CancelButton data-test-id="btnClose" onClick={this.closeModal}>
                      Cancel
                    </CancelButton>
                    <DoneButton
                      type="submit"
                      data-test-id="btnDone"
                      onClick={() => this.sendReply()}
                    >
                      Reply
                    </DoneButton>
                  </DialogActions>}
                </CustomDialog>
                <Box sx={styles.paginationContainer}>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={this.state.queriesToRender.length}
                    rowsPerPage={10}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                  /></Box>
              </Box>
            </Box>
            <Box sx={styles.footerBlock}>
              <Box sx={styles.leftFooter}>
                <Box>Terms and Conditions</Box>
                <Box>Pravicy Policy</Box>
                <Box></Box>
              </Box>

              <Box>
                <Box>© 2024 All Rights Reserved</Box>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styles = {
  mainWrapper: {
    flex: 1,
    flexDirection: 'col',
    backgroundColor: '#f6f6f6'
  },
  mainBlock: {
    display: 'flex',
    width:'100%',
    flexDirection: 'row',
    backgroundColor:'#f6f6f6',
  },
  cellContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '312px',
  },
  cellWrapper: {
    border: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    padding:'5px 8px'
  },
  cellHeader: {
    border: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    padding:'5px 8px',
    fontWeight:'700',
    fontSize:'14px',
    color: '#475569',
    backgroundColor:'white',
    minWidth:'80px'
  },
  cellInfo: {
    fontSize: '12px',
    fontWeight: '700',
    width:'100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  modalItemCol:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  modalName: {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '22px'
  },
  modalSubInfo: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px'
  },
  descriptionContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '280px',
  },
  descriptionText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
    color: '#64748B',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
  },
  menuItemContainer: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "space-between",
    backgroundColor: 'white',
  },
  categoryContainer: {
    minWidth: '100px',
    maxHeight: '200px',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '8px',
    border: '1px',
    '& .MuiSelect-select': {
      '&:focus': {
        outline: 'none',
        border: 'none',
      },
    },
    '&:focus-visible': {
      outline: 'none',
    },
  },
  categoryText: {
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '18px',
    textAlign: 'left',
  },
  repliedBox: {
    backgroundColor: '#E7E5E4',
    padding: '4px 4px 0px 4px',
    borderRadius: '12px',
    fontSize: '10px',
    maxHeight: '18px',
    color: '#78716C',
    fontWeight: '700px',
    marginLeft: '10px',
    textAlign: 'center',
  },
  subject: {
    color: '#0F172A',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '28px',
    maxWidth: '170px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  name: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '700',
    color: '#0F172A',
    maxWidth: '170px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  headerText: {
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: '700',
    color: '#0F172A',
    maxWidth: '170px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  tableContainer: {
    overflowX: 'auto',
    marginLeft: '20px',
    marginRight: '20px',
    marginTop: '30px'
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  statusContainer: {
    height: '22px',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight:'18px',
    color: '#D97706',
    justifyContent: 'center',
    alignContent:'center',
    textAlign: 'center',
    backgroundColor: '#FEF3C7',
    border:'none',
    borderColor:"#FEF3C7",
    borderRadius: '26px',
  },
  newCategoryText: {
    color: '#059669',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  statusContainerSuccess:{
    height: '22px',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight:'18px',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: '#D1FAE5',
    borderRadius: '26px',
    borderColor:"#D1FAE5",
    border:'none',
    color: '#059669'
  },
  completed: {
    background: '#D1FAE5'
  },
  pending: {
    background: '#FEF3C7'
  },
  modalDesc: {
    backgroundColor: '#F0F0F0',
    padding: '12px',
  },
  modalText: {
    color: '#44403C',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400'
  },
  statusText: {
    fontFamily: 'Heebo',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '18px',
    textAlign: 'left',
  },
  replyContainer: {
    backgroundColor: '#000000',
    width: '91px',
    height: '24px',
    textAlign: 'center',
    borderRadius: '4px',
    cursor: "pointer"
  },
  replyText: {
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '700',
  },
  sortByRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '30px'
  },
  selectOptions: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '20px',
    justifyContent: 'space-between'
  },
  headerOptions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '20px'
  },
  deleteBox: {
    borderRadius: '4px',
    border: '1px solid #F87171',
    color: '#DC2626',
    marginLeft: '16px',
    padding: '8px',
    fontWeight: '700',
    fontSize: '16px',
    backgroundColor: '#FDF2F8'
  },
  markReadBox: {
    borderRadius: '4px',
    border: '1px solid #000000',
    marginLeft: '16px',
    padding: '8px',
    fontWeight: '700',
    fontSize: '16px'
  },
  relevText: {
    padding: '8px',
    fontWeight: '700',
    fontSize: '16px'
  },
  footerBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '24px 52px 24px 52px',
    backgroundColor: '#1C1C1C',
    color: '#fff',
  },
  leftFooter: {
    display: 'flex',
    gap: '24px',
    flexGrow:1
  },
  rightFooter: {},

  sideBar: {
    height: '974px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '30px 16px 24px 40px',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff'
  },
  sideBarTop: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  },
  sideNavList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px'
  },
  evenRow: {
    backgroundColor: '#f5f5f5'
  },
  oddRow: {
    backgroundColor: '#ffffff'
  },
  sideNavItem: {
    height: '48px',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    padding: '4px 16px',
    borderRadius: '8px'
  },
  logoutButton: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    color: 'red',
    flexGrow: '1',
    justifyContent: 'start',
    fontSize: '16px',
    textTransform: 'none'
  },
  pageContent: {
    display: 'flex',
    overflowX:'auto',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '24px',
    paddingTop:'30px',
    paddingBottom:'40px'
      },
  navigation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '16px',
  },
  currentNavigationPage: {
    fontWeight: '700',
    fontSize: '24px',
    padding: '4px 8px'
  },
  breadcrumbButton: {
    fontSize: '24px',
    padding: '4px 8px'
  },
  leftActions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    fontSize: '24px'
  },
  blackButton: {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333',
    },
    textTransform: 'none'
  },
  saveButton: {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333',
    },
    height: '44px',
    padding: '10px 16px 6px',
    fontWeight: '700'
  },
  moreButton: {
    color: 'black',
    padding: '10px'
  },

  pathTitle: {
    fontSize: '24px',
    fontWeight: '500'
  },

  filterButtonsBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px'
  },
  filterButton: {
    padding: '8px 16px 4px',
    fontSize: '16px'
  },
  courseCardsBlock: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '40px',
    borderRadius: '4px'
  },
  courseCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '240px'
  },
  courseCardDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '0px 16px 24px 16px'
  },
  iconsStyle: {
    minWidth: '1px'
  },
  textRow: {
    whiteSpace: 'nowrap'
  }
}

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: '720px',
    width: '100%',
    borderRadius: "8px 8px 25px",
    [theme.breakpoints.down('sm')]: {
      margin: "0px",
      maxWidth: "100% !important",
      borderRadius: "0px",
      height: "100vh",
    }
  },
  "& .MuiGrid-root > .MuiGrid-item": {
    paddingTop: "7px",
  },
  "& .dialog-title": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 16px 10px 30px",
    borderBottom: "1px solid #E2E8F0",
    [theme.breakpoints.down('sm')]: {
      padding: "20px 15px 25px 20px",
      borderBottom: "0px",
      justifyContent: "normal",
    },
    "& .heading": {
      fontSize: "24px",
      fontFamily: "Heebo-Bold",
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        fontSize: "14px",
        textAlign: "center",
        textTransform: "uppercase",
      },
    },
    "& .close-icon": {
      cursor: "pointer",
      color: "#334155",
      [theme.breakpoints.down('sm')]: {
        display: "none"
      },
    },
    "& .arrow-icon": {
      cursor: "pointer",
      display: "none",
      [theme.breakpoints.down('sm')]: {
        display: "block"
      },
    }
  },
  "& .content": {
    padding: "28px 40px !important",
    [theme.breakpoints.down('sm')]: {
      padding: "15px !important",
    },
    "& .mt5": {
      marginTop: "5px",
      fontSize: "16px",
      fontFamily: "Heebo-Regular",
    }
  },
  "& .contact-info": {
    fontSize: "16px",
    color: "#0F172A",
    marginBottom: "15px",
    fontFamily: "Heebo-Regular"
  },
  "& .label-title": {
    fontSize: "14px",
    color: "#9D9D9D",
    fontFamily: "Heebo-Bold",
    [theme.breakpoints.down('sm')]: {
      color: "#6A6A6A"
    },
  },
  "& .cool-gray": {
    color: "#64748B"
  },
  "& .dialog-action": {
    padding: "10px 16px 0px",
    borderTop: "1px solid #E2E8F0",
    [theme.breakpoints.down('sm')]: {
      display: "none"
    },
  },
  "& .send-btn": {
    display: "none",
    [theme.breakpoints.down('sm')]: {
      display: "block"
    },
  }
}));

const CustomInput = styled(InputBase)({
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  fontFamily: "Heebo-Regular",
  backgroundColor: "#fff",
  marginTop: "3px",
  fontSize: "14px",
  "& input": {
    padding: "7px 8px",
  },
  "& .MuiInputBase-inputMultiline": {
    fontSize: "14px",
    padding: "5px 8px",
  }
});

const CancelButton = styled(Button)({
  width: "80px",
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "black !important",
  fontSize: "16px !important",
  padding: "7px 15px !important",
  backgroundColor: "#F0F0F0",
  borderRadius: "8px !important",
  "&:hover": {
    backgroundColor: "#F0F0F0",
  },
});

const DoneButton = styled(Button)({
  marginLeft: "15px !important",
  width: "80px",
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "white !important",
  fontSize: "16px !important",
  padding: "7px 15px !important",
  backgroundColor: "black",
  borderRadius: "8px !important",
  "&:hover": {
    backgroundColor: "black",
  },
});

const SendButton = styled(Button)({
  textTransform: "none",
  marginTop: "50px",
  color: "#64748B !important",
  fontSize: "16px !important",
  padding: "12px 28px !important",
  fontFamily: "Heebo-Bold",
  borderRadius: "8px !important",
  backgroundColor: "#F0F0F0",
  "&:hover": {
    backgroundColor: "#F0F0F0",
  },
});

// Customizable Area End
