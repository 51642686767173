export const imgThinkspikeLogo = require('./assets/image_logo.png').default;

export const imgDashboard = require('./assets/image_dashboard.png').default;
export const imgAnalytics = require('./assets/image_analytics.png').default;
export const imgClients = require('./assets/image_clients.png').default;
export const imgContentManagement = require('./assets/image_content_management.png').default;
export const imgContentManagementBlack = require('./assets/cm_black.png').default;
export const imgCourses = require('./assets/image_courses.png').default;
export const imgPackages = require('./assets/image_packages.png').default;
export const imgPhone = require('./assets/image_phone.png').default;
export const imgPhoneWhite = require('./assets/phone_white.png').default;
export const imgUser = require('./assets/image_user.png').default;
export const clientWhite=require('./assets/image_clients_white.png').default


