import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  Slide,
  Snackbar,
  Backdrop,
  InputBase,
  CircularProgress,
  Alert as MuiAlert,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { styled } from "@mui/material/styles";

import ResetPasswordController from "./ResetPasswordController.web";
import Header from "../../../components/src/Header.web";
// Customizable Area End

export default class ResetPassword extends ResetPasswordController {
  // Customizable Area Start
  signUpSchema = () => {
    return Yup.object().shape({
      email: Yup.string().required("*Email is required")
        .email("*Enter valid email")
    });
  }

  renderAlert = () => {
    return (
      <Backdrop
        open={this.state.isOpen}
        onClick={this.handleClose}
        sx={{
          zIndex: "9999999"
        }}
      >
        <Snackbar
          open={this.state.isOpen}
          autoHideDuration={6000}
          onClose={this.handleClose}
          TransitionComponent={(props) => <Slide {...props} direction="down" />}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{
            '&.MuiSnackbar-root': {
              top: '60px !important',
            }
          }}
        >
          <MuiAlert
            icon={false}
            elevation={6}
            variant="filled"
            onClose={this.handleClose}
            sx={{
              "&.MuiAlert-root": {
                padding: "11px 20px",
              },
              display: 'flex',
              alignItems: 'center',
              color: '#000',
              fontSize: "16px",
              borderRadius: '8px',
              backgroundColor: '#fff',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              boxShadow: '0px 6px 15px -3px #00000026',
            }}
          >
            Password reset link has been sent to your email
          </MuiAlert>
        </Snackbar>
      </Backdrop>
    );
  }

  renderResetPassword = () => {
    const RenderButton = this.state.isEmailDisable ? CustomButton : Button;
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box className="form">
          <Box textAlign="center">
            {!this.props.isAdmin && <img src="/builderLogo.svg" alt="logo" className="logo" />}
            <div className="title">Forgot password?</div>
          </Box>
          <Formik
            data-test-id="formik"
            initialValues={{
              email: "",
            }}
            validationSchema={this.signUpSchema}
            onSubmit={(values) => {
              this.resetPassword();
            }}
          >
            {({ errors, touched, setFieldValue, handleSubmit }) => (
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
                className="form"
              >
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Box mb="20px">
                      <label>Email</label>
                      <CustomInput
                        fullWidth
                        data-test-id="email"
                        placeholder="Email address"
                        disabled={this.state.isEmailDisable}
                        value={this.state.email}
                        onChange={(event) => {
                          setFieldValue("email", event.target.value.trim());
                          this.setState({ email: event.target.value.trim(), error: "" });
                        }}
                      />
                      {touched.email && errors.email &&
                        <div className="text-danger">{errors.email}</div>
                      }
                      <div className="text-danger">{this.state.error}</div>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    {!this.state.isEmailDisable &&
                      <CustomButton
                        fullWidth
                        data-test-id="reset"
                        disabled={this.state.loading}
                        type="submit"
                        sx={{
                          height: "45px",
                          marginTop: "15px",
                        }}
                      >
                        {this.state.loading && <CircularProgress size={15} sx={{ color: "#fff", marginRight: "10px", marginBottom: "2px" }} />}
                        Reset Password
                      </CustomButton>
                    }
                    <RenderButton
                      fullWidth
                      data-test-id="sign-in"
                      sx={{
                        height: "45px",
                        marginTop: "15px",
                        color: "#1C1C1C",
                        textTransform: "none",
                        fontFamily: "Heebo-Regular",
                        fontSize: "16px !important",
                      }}
                      onClick={this.onRegistrationPage}
                    >
                      Back to Sign In
                    </RenderButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    );
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Wrapper data-test-id="wrapper">
        {!this.state.isMobile ? (
          <Header navigation={this.props.navigation}>
            {this.renderResetPassword()}
            {this.renderAlert()}
          </Header>
        ) : (
          <Container>
            {this.renderResetPassword()}
            {this.renderAlert()}
          </Container>
        )}
      </Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Box)(({ theme }) => ({
  "& .form": {
    maxWidth: "440px",
    margin: "20px 0px",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
      padding: "0px 10px"
    },
    "& .title": {
      fontSize: "24px",
      fontFamily: 'Heebo-Light',
      color: "181818",
      margin: "17px 0px 35px",
      "& span": {
        fontFamily: 'Heebo-Light'
      }
    },
    "& .logo": {
      textAlign: "center"
    },
    "& label": {
      color: "#9D9D9D",
      fontFamily: "Heebo-Medium",
    },
    "& .remember": {
      color: "#9D9D9D",
      fontSize: "14px",
    },
    "& .forgot": {
      cursor: "pointer",
      fontSize: "14px"
    }
  }
}));

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: "100vh",
  width: "100%",
});

const CustomInput = styled(InputBase)({
  border: "1px solid #9D9D9D",
  borderRadius: "4px",
  backgroundColor: "#fff",
  marginTop: "3px",
  "& input": {
    padding: "11px 14px",
  },
  "&.Mui-disabled": {
    color: "black",
    border: "1px solid #F0F0F0",
    borderRadius: "4px",
    backgroundColor: "#F0F0F0",
    "WebkitTextFillColor": "black",
  }
});

const CustomButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontFamily: "Heebo-Regular",
  color: "white !important",
  fontSize: "16px !important",
  padding: "6px 35px !important",
  backgroundColor: "#1C1C1C",
  borderRadius: "4px !important",
  "&:hover": {
    backgroundColor: "#1C1C1C",
  },
  [theme.breakpoints.down('sm')]: {
    padding: "6px 15px !important"
  },
  "&.Mui-disabled": {
    backgroundColor: "rgb(83 78 78)",
  }
}));
// Customizable Area End