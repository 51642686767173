import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/ApiCall.web";
import React from 'react';
import { toast } from "react-toastify";
import MessageEnum, {
    getName,
  } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
interface IUser {
    account_id: number,
    accepted_on: string,
    email: string
}

export interface IPrivacyPolicyData {
    id: string,
    type: string,
    attributes: {
        id: number,
        description: string,
        created_at: string,
        accepted_by: IUser[]
    }
}
// Customizable Area End
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean,
    privacyPolicyData: IPrivacyPolicyData | null,
    privacyPolicyValue: string
    isMobile: boolean,
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class PrivacyPolicyUserPartController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    privacyPolicyApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            privacyPolicyData: null,
            privacyPolicyValue: "",
            isMobile: window.innerWidth < 900,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        this.getPrivacyPolicy()
        window.addEventListener('resize', this.handleCheckScreenResize);
      }
    async componentWillUnmount() {
        window.removeEventListener('resize', this.handleCheckScreenResize);
    }

    handleCheckScreenResize = () => {
        this.setState({ isMobile: window.innerWidth < 900 });
    }
    getPrivacyPolicy = async () => {
        this.setState({ loading: true })
        this.privacyPolicyApiCallId = await apiCall({
            method: "GET",
            contentType: "application/json",
            navigation: this.props.navigation,
            token: localStorage.getItem("token"),
            endPoint: `bx_block_terms_and_conditions/privacy_policies/latest_record`,
        });
    }

    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.privacyPolicyApiCallId) {
                    if (responseJson.data) {
                        const responseData = responseJson.data as IPrivacyPolicyData
                        this.setState({ privacyPolicyData: responseData, privacyPolicyValue: responseData.attributes.description })
                    } else if (responseJson.error) {
                        toast.error(responseJson.error);
                    }
                }

                this.setState({ loading: false });
            }
        }
        // Customizable Area End
    }
}
