import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showChangePasswordDiallog:boolean,
  password:string,
  confirmPassword:string,
  passwordError:string,
  confirmPasswordError:string,
  isFormValid:boolean,
  firstName:string,
  lastName:string,
  email:string,
  jobTitle:string,
  currentPassword:string,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAdminDetailsCallId = ""
  changePasswordCallId = ""
  logOutApiCallId = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showChangePasswordDiallog:false,
      password:'',
      confirmPassword:'',
      passwordError:'',
      confirmPasswordError:'',
      isFormValid:false,
      firstName:'',
      lastName:'',
      email:'',
      jobTitle:'',
      currentPassword:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getAdminDetailsCallId) {
      this.receiveAdminDetailsResponse(responseJson);
    }
    if (apiRequestCallId === this.changePasswordCallId) {
      this.recieveChangePasswordResponse(responseJson);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getAdminDetails();
    
  }
  validatePassword = (value: string) => {
    let errorMessage = '';

    if (!/[a-z]/.test(value) || !/[A-Z]/.test(value)) {
      errorMessage = 'Password must contain at least one uppercase and one lowercase letter.';
    } else if (!/\d/.test(value)) {
      errorMessage = 'Password must contain at least one numeric value.';
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      errorMessage = 'Password must contain at least one special character.';
    } else if (value.length < 8) {
      errorMessage = 'Password must be at least 8 characters long.';
    }

    this.setState({ passwordError: errorMessage });
  };
  updateFormValidity = () => {
  };
  
  onLogout = () => {
    this.logOutApi();
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailAccountRegistrationMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  validateConfirmPassword = () => {
    const { password, confirmPassword } = this.state;
    const confirmPasswordError = password && confirmPassword && password !== confirmPassword
      ? 'Passwords should match'
      : '';
    this.setState({ confirmPasswordError });
  }
  showChangePasswordDialog = () => {
    this.setState({showChangePasswordDiallog:true})
  }
  closeChangePasswordDialog = () => {
    this.setState({showChangePasswordDiallog:false})
    this.resetCachedStates();
  }
  handlePasswordChange = (event: any) => {
    const password = event.target.value;
    this.setState({ password }, () => {
      this.validatePassword(password);
      this.validateConfirmPassword();
      this.updateFormValidity()
    });
  }
  handleCurrentPasswordChange = (event: any) => {
    const password = event.target.value;
    this.setState({ currentPassword:password });
  }
  handleConfirmPasswordChange = (event:any) => {
    const confirmPassword = event.target.value;
    this.setState({ confirmPassword }, () => {
      this.validateConfirmPassword();
      this.updateFormValidity();
    });
  }
  getAdminDetails = () => {
    let token = localStorage.getItem('token')
    let adminId = localStorage.getItem('id');
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAdminDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_account_groups/admin_managements/${adminId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  receiveAdminDetailsResponse = (response: any) => {
    this.setState({
      firstName: response.data.attributes.first_name,
      lastName: response.data.attributes.last_name,
      email: response.data.attributes.email
    });
  }
  changePasswordApiCall = () => {
    const reqBody = {
      "current_password":this.state.currentPassword,
      "new_password":this.state.password,
      "password_confirmation":this.state.confirmPassword,
    }
    let token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage) 
    );
    this.changePasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_settings/settings/admin_change_password"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reqBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  resetCachedStates = () => {
    this.setState({password:''})
    this.setState({currentPassword:''})
    this.setState({confirmPassword:''})
    this.setState({passwordError:''})
    this.setState({confirmPasswordError:''})
  }
  recieveChangePasswordResponse = (response: any) => {
    if (response.errors) {
      toast.error(response.errors[0].message);
      return;
    } else {
      toast.success(response.message);
      this.setState({showChangePasswordDiallog:false})
      this.resetCachedStates();
    }
  }
  logOutApi = () => {
    
    let token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage) 
    );
    this.logOutApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_settings/settings/logout"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Customizable Area End
}
